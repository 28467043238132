import dashboard from './dashboard';
import role from './role';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [role,dashboard, pages, other, utilities]
};

export default menuItems;
