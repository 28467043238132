import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';

import { useHistory, useParams } from 'react-router-dom';

import { Navigate, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthUser from 'AuthUser';

// ==============================|| SAMPLE PAGE ||============================== //

//

const Recruteur_addOffre = () => {
    let history = useNavigate(); //use for navigate previons
   // const { id } = useParams();
    const [data, setData] = useState({
        titreoffre: '',
        Status: '',
        Descriptif_Poste: '',
        Profil_Recherche: '',
        Remuneration: '',
        AvantageContext: '',
        Localisation: '',
        Points_Incontournables: ''
    });
    const{id,email,role} = AuthUser(); 
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
//   useEffect(() => {
//         if (email != '') {
//             load();
//         } else {
//             history('/login');
//         }
//     }, []);
    const submitForm = (e) => {
        //const formatDate = Moment(data.dateNaissance);
        // alert(formatDate.format('YYYY-MM-DD'));
if(email !='' && role !='')
{
        if (
            data.titreoffre == '' ||
            data.Status == '' ||
            data.Descriptif_Poste == '' ||
            data.Profil_Recherche == '' ||
            data.Remuneration == '' ||
            data.AvantageContext == '' ||
            data.Localisation == '' ||
            data.Points_Incontournables == '' 
          //  data.entreprise == '' 
        ) {
            //alert
            //setData((data.show = true));
            //ajouter candidasett
            alert('Tous les champs sont obligatoire');
        } else {
            console.log(data);
            axios
                .post('https://sourcing.openmind-its.com/api/Offres', {
                    titre_Offre: data.titreoffre,
                    Status: data.Status,
                    Descriptif_Poste: data.Descriptif_Poste,
                    Profil_Recherche: data.Profil_Recherche,
                    Remuneration: data.Remuneration,
                    AvantageContext: data.AvantageContext,
                    Localisation: data.Localisation,
                    Points_Incontournables: data.Points_Incontournables,
                    entreprise:data.entreprise
                })
                .then(function (response) {
                if (response.status === 400) 
                 {
                        alert(response.message);
                 }
                else
                 {
                    setData((data.show = false));
                    alert('bien Ajouter | id : ' + response.data.id);
                    history('/vue_recruteur_tagOffre/' + response.data.id);
                 }
                })
                .catch(function (error) {
                    //   setData((data.show = true));
                    alert(error);
                });
        }
    }
    else{
        alert('une connexion est requise');
    }
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <MainCard title="Offre et Service">
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        label="Titre Offre"
                                        margin="normal"
                                        name="titreoffre"
                                        type="text"
                                        value={data.titreoffre}
                                        onChange={handlChanged}
                                    />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                    <TextField
                                        multiline
                                        fullWidth
                                        label="Nom entreprise"
                                        margin="normal"
                                        name="entreprise"
                                        type="text"
                                        value={data.entreprise}
                                        onChange={handlChanged}
                                    />
                                    </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Etat">Status de L'offre</InputLabel>
                                                <Select labelId="Etat" label="Status" id="Status" name="Status" onChange={handlChanged}>
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="inactive">Inactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Remuneration"
                                                name="Remuneration"
                                                type="text"
                                                value={data.Remuneration}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Profil Recherche"
                                                margin="normal"
                                                name="Profil_Recherche"
                                                type="text"
                                                value={data.Profil_Recherche}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Descriptif Poste"
                                                name="Descriptif_Poste"
                                                type="text"
                                                value={data.Descriptif_Poste}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="AvantageContext"
                                                margin="normal"
                                                name="AvantageContext"
                                                type="text"
                                                value={data.AvantageContext}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Localisation"
                                                margin="normal"
                                                name="Localisation"
                                                type="text"
                                                value={data.Localisation}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Points_Incontournables"
                                                margin="normal"
                                                name="Points_Incontournables"
                                                type="text"
                                                value={data.Points_Incontournables}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item tem xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Ajouter
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addOffre;
