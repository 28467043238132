import { useState } from 'react';
import { Box, Grid, TextField, MenuItem, Input, Select, Button, Alert, FormControl, InputLabel } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import AuthUser from 'AuthUser';
import Moment from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addCandidat = () => {
    let history = useNavigate(); //use for navigate previons
    const { id } = useParams();
    const [data, setData] = useState({
        name: '',
        email: '',
        role: '',
        password: ''
    });
    const [dossierCompetence, setDossierCompetence] = useState([]);
    const { email,role} = AuthUser(); 
    useEffect(() => {
    if(email!='' && role !='')
    {  
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/user/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                setData(json);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }
    else {
        history('/login');
    }
    }, []);
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    //upload cv
    const [cvdata, setcvData] = useState('');
    const handleChanges = (file) => {
        setcvData(file[0]);
    };

    const submitForm = (e) => {

        axios
            .post('https://sourcing.openmind-its.com/api/user/' + id, {
                name: data.name,
                email: data.email,
                role: data.role,
                password: data.password
            })
            .then(function (response) {
                setData((data.show = false));
                history('/vue_recruteur_ListUsers');
            })
            .catch(function (error) {
                //setData((data.show = true));
                alert(error);
            });
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    return (
        <>
            <MainCard title={'Modifier Profile : ' + id}>
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Name"
                                                name="name"
                                                id="name"
                                                type="text"
                                                value={data.name}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                id="email"
                                                type="text"
                                                value={data.email}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                name="password"
                                                id="password"
                                                type="text"
                                                value={data.password}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Role">Filtrer par Role </InputLabel>
                                                <Select
                                                    labelId="Role"
                                                    label="Filtrer par Role"
                                                    id="role"
                                                    name="role"
                                                    onChange={handlChanged}
                                                >
                                                    <MenuItem value="all">All</MenuItem>
                                                    <MenuItem value="Entreprise">Entreprise</MenuItem>
                                                    <MenuItem value="Recruteur">Recruteur</MenuItem>
                                                    <MenuItem value="Candidat">Candidat</MenuItem>
                                                    <MenuItem value="Valideur">Valideur</MenuItem>
                                                    <MenuItem value="Admin">Admin</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Modifier
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
