import { useState } from 'react';
import { Box, Grid, TextField, MenuItem, Input, InputLabel, Select, Button, Alert, FormControl } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';
//session
import { ReactSession } from 'react-client-session';
// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import { useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthUser from 'AuthUser';
import Moment, { updateLocale } from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addCandidat = () => {
    let history = useNavigate(); //use for navigate previons

    const [data, setData] = useState({
        name: '',
        email: '',
        password: '',
        role: '',
        show: 'false',
        status: ''
    });
    const { name, email, password, role } = AuthUser();
    //

    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const submitForm = (e) => {
        // alert(data.name+"|"+data.email+"|"+data.password+"|"+data.role);
    if(name != '' && role === 'Admin')
    {
        if (!(data.name == '' || data.email == '' || data.password == '' || data.role == '')) {
            axios
                .post('https://sourcing.openmind-its.com/api/register', {
                    name: data.name,
                    email: data.email,
                    role: data.role,
                    password: data.password
                })
                .then(function (response) {
                    alert("L'utilisateur bien Ajouter");
                    history('/vue_recruteur_ListUsers');
                    console.log(response);
                })
                .catch(function (error) {
                    //alert(response.message);
                    setData((data.show = true));
                });
        }
      
}
else{ alert('une connexion est requise');
history('/login');
}
    };

    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    //end upload cv
    return (
        <>
            {data.show ? (
                <div></div>
            ) : (
                <Alert variant="filled" severity="error">
                    Merci d'inserer tout les champs !!
                </Alert>
            )}
            <MainCard title="Ajouter Utilisateur">
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Nom"
                                                name="name"
                                                id="name"
                                                type="text"
                                                value={data.name}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                id="email"
                                                type="email"
                                                value={data.email}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Password"
                                                name="password"
                                                id="password"
                                                type="text"
                                                value={data.password}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Etat">Role</InputLabel>
                                                <Select labelId="Role" label="Role" id="role" name="role" onChange={handlChanged}>
                                                    <MenuItem value="Entreprise">Entreprise</MenuItem>
                                                    <MenuItem value="Recruteur">Recruteur</MenuItem>
                                                    <MenuItem value="Candidat">Candidat</MenuItem>
                                                    <MenuItem value="Valideur">Valideur</MenuItem>
                                                    <MenuItem value="Admin">Admin</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Ajouter
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
