import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Input,
    InputLabel,
    Select,
    Button,
    Alert,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    FormLabel
} from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

import { useHistory, useParams } from 'react-router-dom';
import Moment, { updateLocale } from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addCandidat = () => {
    const { id } = useParams();
    let history = useNavigate(); //use for navigate previons

   
    const handlChangedExp = (e) => {
        setExp({ ...exp, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if(email!='' && (role==='Admin' || role==='Recruteur')){
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/showexp/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                //console.log(json[0]);
                setExp(json[0]);
                //setexper(json.experience);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }

else{
    history('/login');
}
    }, []);
    const submitForm = (e) => {
       
        // alert(formatDate.format('YYYY-MM-DD'));
        console.log(exp);
        axios
            .put('https://sourcing.openmind-its.com/api/DossierCompetence/' + exp.id, {
                profil_id: exp.profil_id,
                entreprise: exp.entreprise,
                secteur: exp.secteur,
                datefin: exp.datefinExp,
                datedebut: exp.datedebutExp,
                mission: exp.mission,
                cadretechnique: exp.cadretechnique,
                methodologies: exp.methodologies
            })
            .then(function (response) {
                //  console.log(response.data);
                alert('Expérience bien Modifier');
                history('/vue_recruteur_listFicheEntretien');
            })
            .catch(function (error) {
                //alert(response.message);
                alert('error');
            });
    };
    //add Experience
    const redirection = (e) => {
        // history('/vue_entreprise_offre');
        setOpen(true);
    };
    const [open, setOpen] = React.useState(false);
    const [exp, setExp] = useState({
        profil_id: '',
        entreprise: '',
        secteur: '',
        datefin: '',
        datedebut: '',
        mission: '',
        cadretechnique: '',
        methodologies: ''
    });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const ajouterExp = (event) => {
        console.log(exp);
        axios
            .post('https://sourcing.openmind-its.com/api/DossierCompetence', {
                profil_id: idprofile,
                entreprise: exp.entreprise,
                secteur: exp.secteur,
                datefin: exp.datefinExp,
                datedebut: exp.datedebutExp,
                mission: exp.mission,
                cadretechnique: exp.cadretechnique,
                methodologies: exp.methodologie
            })
            .then(function (response) {
                alert('Expérience Bien Ajouter');
                setOpen(false);
            })
            .catch(function (error) {
                alert('error');
            });
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    const [startDate, setStartDate] = useState(new Date());
    //upload cv
   

    //end upload cv
    return (
        <>
          
            <MainCard title="Modifier Expériance">
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="entreprise"
                                                name="entreprise"
                                                id="entreprise"
                                                type="text"
                                                value={exp.entreprise}
                                                onChange={handlChangedExp}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="secteur"
                                                name="secteur"
                                                id="secteur"
                                                type="text"
                                                value={exp.secteur}
                                                onChange={handlChangedExp}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <InputLabel for="datedebutExp">Date de debut</InputLabel>
                                            <Input
                                                fullWidth
                                                id="datedebutExp"
                                                onChange={handlChangedExp}
                                                name="datedebutExp"
                                                type="Date"
                                                selected={startDate}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <InputLabel for="datefinExp">Date de fin</InputLabel>
                                            <Input
                                                fullWidth
                                                id="datefinExp"
                                                onChange={handlChangedExp}
                                                name="datefinExp"
                                                type="Date"
                                                selected={startDate}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="mission"
                                                name="mission"
                                                id="mission"
                                                type="text"
                                                value={exp.mission}
                                                onChange={handlChangedExp}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="cadretechnique"
                                                name="cadretechnique"
                                                id="cadretechnique"
                                                type="text"
                                                value={exp.cadretechnique}
                                                onChange={handlChangedExp}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="methodologie"
                                                name="methodologie"
                                                id="methodologie"
                                                type="text"
                                                value={exp.methodologies}
                                                onChange={handlChangedExp}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        Modifier
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
