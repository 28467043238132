// assets
import { IconKey } from '@tabler/icons';
import AuthUser from 'AuthUser';
// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

let pages = '';
const { name, email, role } = AuthUser();
if (name == '') {
    pages = {
        id: 'pages',
        title: 'Pages',
        caption: 'Pages Caption',
        type: 'group',
        children: [
            {
                id: 'authentication',
                title: 'Authentication',
                type: 'collapse',
                icon: icons.IconKey,

                children: [
                    {
                        id: 'login3',
                        title: 'Login',
                        type: 'item',
                        url: 'login'
                    },
                    {
                        id: 'register3',
                        title: 'Register',
                        type: 'item',
                        url: 'register'
                    }
                ]
            }
        ]
    };
} else {
    pages = {
        id: 'pages',
        title: '',
        caption: 'Pages Caption',
        type: 'group',
        children: []
    };
}

export default pages;
