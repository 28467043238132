import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
//views Candidats
import Espace_candidat from 'views/espace_candidat';
import Espace_entreprise from 'views/vue_entreprise_offre';
import Espace_recruteur from 'views/vue_recruteur_listCandidats';


//views Entreprise
import Entreprise_profileCandidat from 'views/vue_entreprise_profileCandidat';
import Entreprise_offre from 'views/vue_entreprise_offre';
import Entreprise_detailOffre from 'views/vue_entreprise_detailOffre';
import Entreprise_listOffres from 'views/vue_entreprise_listOffres';
import Entreprise_modifierOffres from 'views/vue_entreprise_modifierOffre';
import Entreprise_tagOffre from 'views/vue_entreprise_tagOffre';
import Entreprise_profile from 'views/vue_entreprise_profil';
//views Recruteur
import Recruteur_listCandidats from 'views/vue_recruteur_listCandidats';
import Recruteur_addCandidat from 'views/vue_recruteur_addCandidat';
import Recruteur_modifierCandidat from 'views/vue_recruteur_modifierCandidat';
import Recruteur_datailCandidat from 'views/vue_recruteur_detailCandidat';
import Recruteur_addProfile from 'views/vue_recruteur_addProfile';
import Recruteur_tagOffre from 'views/vue_recruteur_tagOffre';
import Recruteur_dictionnaire from 'views/vue_recruteur_dictionnaire';
import Recruteur_listOffres from 'views/vue_recruteur_listOffres';
import Recruteur_updateProfile from 'views/vue_recruteur_updateProfile';
import Recruteur_listCandidatOffre from 'views/vue_recruteur_listCandidatOffre';
import Recruteur_ficheDentretien from 'views/vue_recruteur_ficheDentretien';
import Recruteur_modifierProfile from 'views/vue_recruteur_modifierProfile';
import Recruteur_listFicheEntretien from 'views/vue_recruteur_listFicheEntretien';
import Recruteur_dataillFicheEntretien from 'views/vue_recruteur_detailFicheEntretien';
import Recruteur_ModifierficheDentretien from 'views/vue_recruteur_ModifierficheDentretien';
import Recruteur_ModifierExperience from 'views/vue_recruteur_ModifierExperience';
import Recruteur_TextAudio from 'views/vue_recruteur_textaudio';
import Recruteur_ProfileListOffers from 'views/vue_recruteur_ProfileListOffres';
import Recruteur_ListUsers from 'views/vue_recruteur_listUsers';
import Recruteur_AddUser from 'views/vue_recruteur_addUser';
import Recruteur_UpdateUser from 'views/vue_recruteur_modifierProfile-ad';
import Recruteur_listEntreprises from 'views/vue_recruteur_listEntreprise';
import Recruteur_addEntreprise from 'views/vue_recruteur_addEntreprise';
import Recruteur_addOffre from 'views/vue_recruteur_addoffre';
import Recruteur_detailEntreprises from 'views/vue_recruteur_detailEntreprise';
//public
import Public_listOffres from 'views/vue_public_listOffres';
import Public_offre from 'views/vue_public_Offre';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// sample page routing
const SamplePage = Loadable(lazy(() => import('views/vue_entreprise_offre')));

// PageAcceill routing
const PageAcceill = Loadable(lazy(() => import('views/espace_candidat')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

import { useState, useEffect } from 'react';
//session
import { ReactSession } from 'react-client-session';
import { Navigate, useNavigate } from 'react-router-dom';
//auth
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'espace_candidat',
            element: <Espace_candidat />
        },
        {
            path: 'vue_entreprise_offre/:id',
            element: <Entreprise_offre />
        },
        {
            path: 'vue_recruteur_addoffre',
            element: <Recruteur_addOffre />
        },

        {
            path: 'vue_entreprise_listOffres/:id',
            element: <Entreprise_listOffres />
        },
        {
            path: 'vue_entreprise_profileCandidat/:id',
            element: <Entreprise_profileCandidat />
        },
        {
            path: 'vue_entreprise_modifierOffre/:id',
            element: <Entreprise_modifierOffres />
        },
        {
            path: 'vue_entreprise_tagOffre/:id',
            element: <Entreprise_tagOffre />
        },
        {
            path: 'vue_entreprise_profil/:id',
            element: <Entreprise_profile />
        },
        {
            path: 'vue_recruteur_listCandidats',
            element: <Recruteur_listCandidats />
        },
        {
            path: 'vue_recruteur_listEntreprise',
            element: <Recruteur_listEntreprises />
        },
        {
            path: 'vue_recruteur_detailEntreprise/:id',
            element: <Recruteur_detailEntreprises />
        },
        {
            path: 'vue_recruteur_addCandidat',
            element: <Recruteur_addCandidat />
        },
        {
            path: 'vue_recruteur_addEntreprise',
            element: <Recruteur_addEntreprise />
        },
        {
            path: 'vue_recruteur_modifierCandidat/:id',
            element: <Recruteur_modifierCandidat />
        },
        {
            path: 'vue_recruteur_detailCandidat/:id',
            element: <Recruteur_datailCandidat />
        },
        {
            path: 'vue_recruteur_addProfile/:id',
            element: <Recruteur_addProfile />
        },
        {
            path: 'vue_recruteur_tagoffre/:id',
            element: <Recruteur_tagOffre />
        },
        {
            path: 'vue_recruteur_updateProfile/:id',
            element: <Recruteur_updateProfile />
        },
        {
            path: 'vue_recruteur_dictionnaire',
            element: <Recruteur_dictionnaire />
        },
        {
            path: 'vue_recruteur_listOffres',
            element: <Recruteur_listOffres />
        },
        {
            path: 'vue_recruteur_listCandidatOffre/:id',
            element: <Recruteur_listCandidatOffre />
        },
        {
            path: 'vue_recruteur_ficheDentretien/:idprofile/:idoffr',
            element: <Recruteur_ficheDentretien />
        },
        {
            path: 'vue_recruteur_modifierProfile/:id',
            element: <Recruteur_modifierProfile />
        },
        {
            path: 'vue_recruteur_listFicheEntretien',
            element: <Recruteur_listFicheEntretien />
        },
        {
            path: 'vue_recruteur_detailFicheEntretien/:id',
            element: <Recruteur_dataillFicheEntretien />
        },
        {
            path: 'vue_recruteur_ModifierficheDentretien/:idprofile/:idoffr/:id',
            element: <Recruteur_ModifierficheDentretien />
        },
        {
            path: 'vue_recruteur_ModifierExperience/:id',
            element: <Recruteur_ModifierExperience />
        },
        {
            path: 'vue_recruteur_textaudio/:id',
            element: <Recruteur_TextAudio />
        },
        {
            path: 'vue_recruteur_ProfileListOffres/:id',
            element: <Recruteur_ProfileListOffers />
        }, 
        {
            path: 'vue_recruteur_ListUsers',
            element: <Recruteur_ListUsers />
        }, 
        {
            path: 'vue_recruteur_AddUser',
            element: <Recruteur_AddUser />
        }, 
        {
            path: 'vue_recruteur_UpdateUser/:id',
            element: <Recruteur_UpdateUser />
        },
        {
            path: 'vue_public_listOffres',
            element: <Public_listOffres />
        },
        {
            path: 'vue_public_Offre/:id',
            element: <Public_offre />
        },
        {
            path: 'logout',
            element: <UtilsColor />
        },
        {
            path: 'profile',
            element: <UtilsShadow />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },

        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'login',
            element: <AuthLogin3 />
        },
        {
            path: 'register',
            element: <AuthRegister3 />
        }
    ]
};

export default MainRoutes;
