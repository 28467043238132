import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import AuthUser from 'AuthUser';
import { Button, Grid, FormControl, InputLabel, Select, MenuItem, Box, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import DownloadLink from 'react-download-link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//session
import { ReactSession } from 'react-client-session';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
//table
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';


const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function Recruteur_detailEntreprises() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    //
    // ==============================|| SAMPLE PAGE ||============================== //
    let history = useNavigate();
    //console.log(id);
    const [data, setData] = useState({});
 
    //email
  
    //envoi mail
    const handlChanged = (e) => {
        setEmail({ ...em, [e.target.name]: e.target.value });
        setOpen(true);
    };
    const { name, email} = AuthUser();
    const{id}=useParams();
    //

    useEffect(() => {
        if (name != '' && email!='') {
            load();
        } else {
            history('/login');
        }
    }, []);

    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/entreprise/'+ id;
                const resp = await fetch(requrl);
                const json = await resp.json();

              //  sete(json.email);
               console.log(json);
                setData(json);
                //setProfile(json.profils);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addProfile/' + id);
    };


 
    const handleSearch = (event) => {
        if (event.target.value != '') {
            setEmail(em.filter((tag) => tag.titre.toLowerCase().includes(event.target.value.toLowerCase())));
        } else {
            //console.log(dectionnarecopy);
            //setEmail(dectionnarecopy);
        }
    };
   
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    //***************************************************** */
    const changelab = (e) => {
        setc({ ...c, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        setOpen(false);
    };
  
    //end email
    const redirectionEmail = (e) => {
        // history('/vue_entreprise_offre');
        setOpen(true);
    };
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const regenerertag = (e, id) => {};
    // const listOffres = (e, id) => {
    //     history('/vue_recruteur_ProfileListOffres/' + id);
    // };
  

    //alert(data.nom);
    //
    return (
        <div>
          
    
            <MainCard title="Détail Candidat">
                <Accordion expanded={expanded === data.id} onChange={handleChange(data.id)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>
                            details entreprise: {data.nom} 
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                       
                        <Typography>Email :</Typography>
                        <Typography>--{data.email}</Typography>
                        <Typography>Adresse :</Typography>
                        <Typography>-{data.adresse}</Typography>
                        <Typography>Pays :</Typography>
                        <Typography>-{data.pays}</Typography>

                        <Typography>Status de l'entreprise :</Typography>
                        <Typography>-{data.status}</Typography>
                        <Typography>contact :</Typography>
                        <Typography>-{data.contact}</Typography>
                        <Typography>Informations entreprise :</Typography>
                        <Typography>-{data.Description}</Typography>
                        <Typography>Status :</Typography>
                        <Typography>-{data.status}</Typography>
                      
                        <Link to={'/vue_recruteur_modifierCandidat/' + data.id}>Modifier Entreprise</Link>
                    </AccordionDetails>
                </Accordion>
            </MainCard>
        </div>
    );
}
