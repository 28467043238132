import { useState } from 'react';
import { Box, Grid, TextField, MenuItem, Input, InputLabel, Select, Button, Alert, FormControl } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';
//session
import { ReactSession } from 'react-client-session';
// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import { useEffect } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthUser from 'AuthUser';
import Moment, { updateLocale } from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addCandidat = () => {
    let history = useNavigate(); //use for navigate previons
    const [data, setData] = useState({
        nom: '',
        prenom: '',
        dateNaissance: new Date(),
        ville: '',
        pays: '',
        email: '',
        cv: '',
        titre: '',
        etat: '',
        mobilite: '',
        active: '',
        pretentionSalarial: '',
        show: 'false',
        status: ''
  
    });
    const { name, email, password, role } = AuthUser();
    //

    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const up = (e) => {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            console.log('*********************');
            console.log(e.target.result);
        };
    };
    const submitForm = (e) => {
if(email !='')
{
        const formatDate = Moment(data.dateNaissance);
        const formatDatedebut = Moment(data.datedebut);
        const formatDatefin = Moment(data.datefin);
        // alert(formatDate.format('YYYY-MM-DD'));

        const fData = new FormData();
        fData.append('cv', cvdata);
        console.log(fData);

        axios
            .post('https://sourcing.openmind-its.com/api/Candidats', {
                nom: data.nom,
                prenom: data.prenom,
                datenaissance: formatDate.format('YYYY-MM-DD'),
                ville: data.ville,
                pays: data.pays,
                email: data.email,
                titreduprofil: data.titre,
                etatduprofil: data.status,
                mobilite: data.mobilite,
                typeduprofil: data.active,
                pretentionssalariales: data.pretentionSalarial,
                status: data.status
            })
            .then(function (response) {
                axios
                    .post('https://sourcing.openmind-its.com/api/Candidats/uploadcvs/' + response.data.data.id, fData)
                    .then((res) => {
                        alert('Ok');

                        history('/vue_recruteur_listCandidats');
                    })
                    .catch((e) => {
                        // console.log('error', e);
                        alert('merci de ressayer avec un autre CV !!');
                    });
                setData((data.show = false));
                // alert(response.message);
                // history('/vue_recruteur_listCandidats');
            })
            .catch(function (error) {
                //alert(response.message);
                setData((data.show = true));
            });
        }
        else {
            alert('une connexion est requise');
            history('/login');
        }
    };

    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    const [startDate, setStartDate] = useState(new Date());
    const [startDatedebut, setStartDatedebut] = useState(new Date());
    const [startDatefin, setStartDatefin] = useState(new Date());
    //upload cv
    const [cvdata, setcvData] = useState('');
    const handleChanges = (file) => {
        setcvData(file[0]);
    };

    //end upload cv
    return (
        <>
            <MainCard title="Ajouter Candidat">
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Nom"
                                                name="nom"
                                                id="nom"
                                                type="text"
                                                value={data.name}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Prénom"
                                                name="prenom"
                                                id="prenom"
                                                type="text"
                                                value={data.prenom}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Ville"
                                                id="ville"
                                                name="ville"
                                                type="text"
                                                value={data.ville}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Pays"
                                                name="pays"
                                                id="pays"
                                                type="text"
                                                value={data.pays}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                id="email"
                                                type="email"
                                                value={values.email}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <input name="cv" id="cv" type="file" onChange={(e) => handleChanges(e.target.files)} />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Titre Profile"
                                                name="titre"
                                                id="titre"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.titre}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Etat">Status de L'offre</InputLabel>
                                                <Select labelId="Etat" label="Status" id="etat" name="status" onChange={handlChanged}>
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="archive">Archive</MenuItem>
                                                    <MenuItem value="invalid">Invalide</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Mobilité"
                                                name="mobilite"
                                                id="mobilite"
                                                type="text"
                                                value={data.mobilite}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Type Profile"
                                                name="active"
                                                id="active"
                                                type="text"
                                                value={data.active}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Prétention Salarial"
                                                name="pretentionSalarial"
                                                id="pretentionSalarial"
                                                type="Nember"
                                                value={data.pretentionSalarial}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Ajouter
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
