import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem,
    label
} from '@mui/material';
//table

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table
import * as React from 'react';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import { FaceRetouchingOffSharp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AuthUser from 'AuthUser';

// ==============================|| SAMPLE PAGE ||============================== //
const Rcruteur_dictionnaire = () => {
    let history = useNavigate();
    const { name, email, password, role } = AuthUser();
    //console.log(senddata);
    //

    //
    const [dectionnare, setDectionnare] = useState([]);
    const [data, setData] = useState({
        label: ''
    });
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const [dectionnarecopy, setDectionnarecopy] = useState([]);
    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Dictionnaire';
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                setDectionnare(json);
                setDectionnarecopy(json);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    useEffect(() => {
        if(email!='' && role!='')
        {
        load();
        }
        else history('/login');
    }, []);

    const redirection = (e) => {
        // history('/vue_entreprise_offre');
        setOpen(true);
    };
    //Searshing
    //const [search, setSearch] = React.useState('');

    const handleSearch = (event) => {
        if (event.target.value != '') {
            setDectionnare(dectionnare.filter((tag) => tag.label.toLowerCase().includes(event.target.value.toLowerCase())));
        } else {
            console.log(dectionnarecopy);
            setDectionnare(dectionnarecopy);
        }

        // setSearch(event.target.value);
    };
    //end searshing

    //supprimer
    const supprimer = async (id) => {
        alert('suprimer : ' + id);
        axios
            .delete('https://sourcing.openmind-its.com/api/Dictionnaire/' + id)
            .then(function (response) {
                //history('/vue_entreprise_listOffres');
                //window.location.reload(false);
                // setDectionnare(dectionnare.filter((tag) => tag.label.toLowerCase().includes(event.target.value.toLowerCase())));
                // setDectionnare(dectionnarecopy);
                // setDectionnarecopy(dectionnare);
                load();
            })
            .catch(function (error) {
                alert(error);
                // setData((data.show = true));
            });
    };
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const ajouterTag = (event) => {
        console.log(data.label);
        axios
            .post('https://sourcing.openmind-its.com/api/Dictionnaire', {
                label: data.label
            })
            .then(function (response) {
                load();
                alert(response.data);
                setOpen(false);
            })
            .catch(function (error) {
                alert(error);
            });
    };
    return (
        <React.Fragment>
            <MainCard title="Dictionnaire de tags">
                <Button onClick={redirection} variant="contained" color="primary">
                    Ajouter Tag
                </Button>
                <Box mt={2} sx={{ float: 'right' }}>
                    <label htmlFor="search">
                        Search a Tag:
                        <input id="search" type="text" onChange={handleSearch} />
                    </label>
                </Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Ajouter Tag</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Insérer Label du Tag</DialogContentText>
                        <TextField fullWidth label="label" name="label" id="label" type="text" value={data.label} onChange={handlChanged} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ajouterTag}>Ajouter</Button>
                        <Button onClick={handleClose}>Annuler</Button>
                    </DialogActions>
                </Dialog>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid item>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell align="center">LABEL</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {dectionnare.map((row) => (
                                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="center">{row.label}</TableCell>

                                                            <TableCell align="right">
                                                                {' '}
                                                                <AnimateButton>
                                                                    <Button
                                                                        onClick={() => supprimer(row.id)}
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </AnimateButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </React.Fragment>
    );
};
export default Rcruteur_dictionnaire;
