import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5)
}));
//table
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthUser from 'AuthUser';


const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    //
    // ==============================|| SAMPLE PAGE ||============================== //

    let history = useNavigate();
    const [entreprise,setEntreprise]=useState([]);

    const { id } = useParams();
    const{email,role}=AuthUser();
    //console.log(id);
    const [data, setData] = useState({
        titre_Offre: '',
        Status: '',
        Descriptif_Poste: '',
        Profil_Recherche: '',
        Remuneration: '',
        AvantageContext: '',
        Localisation: '',
        Points_Incontournables: ''
    });
    const [tage, setTage] = useState([]);
     
    const [profile, setProfile] = useState([]);
    useEffect(() => {
        if( email !='' && role=='Entreprise' )
        {
           load();
        }
        else{
              history('/login');
        }
        
    }, []);
    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Offres/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                //console.log(json['offre']);
                console.log(json['offre']);
                console.log(json['tags']);
                setData(json['offre']);
                setTage(json['tags']);
                setEntreprise(json['entreprise']);

            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    //console.log(data.AvantageContext);
    const redirection = (e) => {
        history('/vue_recruteur_addProfile/' + id);
    };
    //
    const handleDelete = (chipToDelete) => () => {
        //setTage((tags) => tags.filter((tag) => tag.id !== chipToDelete.id));
        setTage(tage.filter((tag) => tag.id !== chipToDelete.id));
    };

    const [ta, setTa] = useState([]);

    const modifier = (e) => {
           console.log(tage);
    //    const serializedData= qs.stringify({data:data,tags:tage});
       // const querytags=qs.stringify(tage);
        axios
            .put('https://sourcing.openmind-its.com/api/Tage_Offres/'+id, {
                tages: tage
            })
            .then(function (response) {
                load();
                alert('Tages Bien Valider');
                //const serializedRow = queryString.stringify({ row });

                history(`/vue_entreprise_modifierOffre/${id}?data=${encodeURIComponent(JSON.stringify(data))}`);
                // history.push({
                //     pathname: `/vue_entreprise_modifierOffre/${id}`,
                //     state: { data: data }
                //   });
            })
            .catch(function (error) {
                alert(error);
            });
    };
    const regenerer = (e) => {
        axios
            .get('https://sourcing.openmind-its.com/api/regenereTages/' + id)
            .then(function (response) {
                load();
                alert('Tages Bien regénere');
                // history('/vue_entreprise_modifierOffre/' + id);
                handleDelete(tage);
            })
            .catch(function (error) {
                alert(error);
            });
    };

    return (
        <div>
            <MainCard title="Détails Offre">
                <Typography gutterBottom variant="h5" component="div">
                    Titre :
                </Typography>
                <Typography> {data.titre_Offre}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Points_Incontournables :{' '}
                </Typography>
                <Typography>{data.Points_Incontournables}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Descriptif_Poste :{' '}
                </Typography>
                <Typography> {data.Descriptif_Poste}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Profil_Recherché :{' '}
                </Typography>
                <Typography> {data.Profil_Recherché}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Remuneration :{' '}
                </Typography>
                <Typography>{data.Remuneration}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Localisation :{' '}
                </Typography>
                <Typography> {data.Localisation}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    AvantageContext :{' '}
                </Typography>
                <Typography>{data.AvantageContext}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Status :{' '}
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Nom Entreprise :{' '}
                </Typography>
                <Typography> {entreprise.nom}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Informations Entreprise :{' '}
                </Typography>
                <Typography> {entreprise.info_complementaire}</Typography>
                <Typography> {data.Status}</Typography>
                <MainCard title="List Tages Offre">
                    {tage.map((data) => (
                        <ListItem key={data.id}>
                            <Chip
                                icon={TagFacesIcon}
                                label={data.label}
                                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                            />
                        </ListItem>
                    ))}
                </MainCard>
                <Box sx={{ p: 2, float: 'left' }}>
                    <Typography component="button">
                        <Button
                            disableElevation
                            onChange={handleChange}
                            onClick={modifier}
                            size="large"
                            variant="contained"
                            color="primary"
                        >
                            Modifier L'Offre
                        </Button>
                    </Typography>
                </Box>
                <Box sx={{ p: 2, float: 'right' }}>
                    <Typography component="button">
                        <Button
                            disableElevation
                            onChange={handleChange}
                            onClick={regenerer}
                            size="large"
                            variant="contained"
                            color="primary"
                        >
                            Regénérer les tages
                        </Button>
                    </Typography>
                </Box>
            </MainCard>
        </div>
    );
}
