import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { Button, Box, MenuItem, Grid, Select, FormControl, InputLabel, Input, TextField, FormHelperText } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import DownloadLink from 'react-download-link';
import ReactAudioPlayer from 'react-audio-player';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CardMedia } from '@mui/material';
//table
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
//dialogue
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import AuthUser from 'AuthUser';

//end dialogue
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    //
    // ==============================|| SAMPLE PAGE ||============================== //

    let history = useNavigate();
    const { id } = useParams();
    const { name, email, password, role } = AuthUser();
    //console.log(id);
    const [data, setData] = useState({
        autreprocessus: '',
        avantages_souh: '',
        date_dispo: '',
        date_entrtien: '',
        disponibilite: '',
        duree_preavis: '',
        est_en_poste: '',
        hard_skills: '',
        id: '',
        mobile: '',
        motiv_attente: '',
        motiv_changt: '',
        nv_avancement: '',
        offre_id: '',
        points_inco: '',
        profil_id: '',
        projet_formation: '',
        recruteur: '',
        remun_brute_act: '',
        remun_brute_souh: '',
        remun_fixe_act: '',
        remun_fixe_souh: '',
        soft_skills: '',
        updated_at: '',
        zone_mobilite: '',
        status:''
        
    });
    const handlChangedExp = (e) => {
        setExp({ ...exp, [e.target.name]: e.target.value });
    };
    const handlChangedGen = (e) => {
        setGen({ ...gen, [e.target.name]: e.target.value });
    };
    const [exper, setexper] = useState([]);
    useEffect(() => {
        if(email!='')
        {
         load();
         AudioPlayer();
        }
        else history('/login');
    }, []);
    // const readaudio =(e)=>{

    //     async function fa() {
    //         try {
    //             const requrl = 'https://sourcing.openmind-its.com/api/audio/get/'+ id;
    //             const resp = await fetch(requrl);
    //             const json = await resp.json();
    //             console.log(json.fiche[0]);
    //             setData(json.fiche[0]);
    //             setexper(json.experience);
    //            // console.log(data);
    //         } catch (error) {
    //             console.error('error');
    //         }
    //     }
    //     fa();

    // };

///lecture de l'audio
const [audioSource, setAudioSource] = useState('');
const AudioPlayer = () => {
      const fetchAudio = async () => {
        
        axios
        .post('https://sourcing.openmind-its.com/api/audio/get/'+ id)
    .then(function (response) 
    {
       // console.log(response.data.file);
    ///si l'audio exsite pour cette fiche d'entretien
        if(response.data.file !='')
        {
         const audioSource='https://sourcing.openmind-its.com/audios/'+response.data.file;
         setAudioSource(audioSource);
        }
        //console.log(audioSource);

    })
    .catch(function (error) {
        // alert("aucune audio");
        console.log('erreur de chargement de l\'audio');
        });

    };
  
      fetchAudio();
      // Cleanup the Blob URL when the component unmounts
    //  return () => URL.revokeObjectURL(audioSource);
    
};
    const load = (e) => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/fichEntretien/'+ id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json.fiche[0]);
                setData(json.fiche[0]);
                setexper(json.experience);
               // console.log(data);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addProfile/' + id);
    };
    //add Experience
    const redirectionExp = (e) => {
        // history('/vue_entreprise_offre');
        setOpen(true);
    };
    const theme = useTheme();
    //gen text audio
    const redirectionGen = (e) => {
        // history('/vue_entreprise_offre');
        setOpengen(true);
    };
    const [openlink, setOpenlink] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [opengen, setOpengen] = React.useState(false);
    const [exp, setExp] = useState({
        profil_id: '',
        entreprise: '',
        secteur: '',
        datefin: '',
        datedebut: '',
        mission: '',
        cadretechnique: '',
        methodologie: ''
    });
    //gen
    const [gen, setGen] = useState({
        link:''
    });
    const [audio,setaudio]=useState({});

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleCloselink = () => {
        setOpenlink(false);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseGen = () => {
        setOpengen(false);
    };
    //lire link
    const lirelink = () => {
        //alert(gen.link);
        setOpenlink(true);
    };
    const [file, setFile] = useState({});
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setGen({ ...gen, [event.target.name]: event.target.value });

      };
///ajout de l'audio
    const ajoutergen = (event) => {
if(file!='')
{
        const linkaudio=file;
        console.log(linkaudio)
        const formData = new FormData();
        formData.append('audio',linkaudio);
        console.log(formData.get('audio'));
        handleCloseGen();
       // alert("test");
        axios.post('https://sourcing.openmind-its.com/api/audio/upload/'+id, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then((response) => {
          console.log(response.data.message);
          // Handle success
          alert("Audio bien ajoutee");
          window.location.reload();

         })
        .catch((error) =>
        {
          console.error(error);
          alert("Erreur d'ajout");
          // Handle error
        });
    }
    else{
        alert('vous devez selectionner un audio');
    }
       // alert('En cours de traitement !!! ');
        // console.log(data);
        //window.open('/vue_recruteur_textaudio/1');
        // history('/vue_recruteur_ModifierficheDentretien/1/1/1');
        /* axios
            .post('https://sourcing.openmind-its.com/api/ntentretien/' + id + '/fr', fData)
            .then(function (response) {
                alert("le texte de l'audio a été générer");
                console.log('response');

                console.log(response.data);
                if (response.data.id) {
                    window.open('/vue_recruteur_textaudio/' + response.data.id);
                }
                setOpengen(false);
                // load();
            })
            .catch(function (error) {
                alert("error e texte de l'audio n'a été générer");
            });
            */
    };
//supprimer audio
    const supprimerAudio =()=>{
        axios
        .post('https://sourcing.openmind-its.com/api/supprimerAudio/'+id)
    .then(function (response) {
        alert('Audio est bien supprimé ');
        window.location.reload();
    })
    .catch(function (error) {
        alert('Erreur de suppression');

    });
};

    // }
     const ajouterExp = (event) => {
        console.log(exp);
        axios
            .post('https://sourcing.openmind-its.com/api/DossierCompetence', {
                profil_id: data.profil_id,
                entreprise: exp.entreprise,
                secteur: exp.secteur,
                datefin: exp.datefinExp,
                datedebut: exp.datedebutExp,
                mission: exp.mission,
                cadretechnique: exp.cadretechnique,
                methodologies: exp.methodologie
            })
            .then(function (response) {
                alert('Expérience Bien Ajouter');
                setOpen(false);
                load();
            })
            .catch(function (error) {
                alert('Expérience Bien Ajouter');
                setOpen(false);
                load();
            });
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    const [startDate, setStartDate] = useState(new Date());
    //upload cv
    const [cvdata, setcvData] = useState('');
    const handleChanges = (file) => {
        setcvData(file[0]);
    };
    //alert(data.nom);
    //
if(role!='Entreprise')
{
    return (
        <div>
            <MainCard title="Détails fiche D'entretien ">
                <Grid mb={2} container spacing={2} alignItems="center" justifyContent="center">
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Button onClick={redirectionExp} variant="contained" color="primary">
                            Ajouter Expérience
                        </Button>
                        <Button onClick={redirectionGen} variant="contained" color="primary">
                            Ajouter l'audio
                        </Button>
                    </Grid>
                
                    {/* <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Box sx={{ float: 'right', width: '14rem' }}>
                            <Button  variant="contained" color="primary">
                                Générer le texte de l'audio
                            </Button>
                        </Box>
                    </Grid> */}
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Ajouter Expérience</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Insérer Label du Tag</DialogContentText>
                        <TextField
                            fullWidth
                            label="entreprise"
                            name="entreprise"
                            id="entreprise"
                            type="text"
                            value={exp.entreprise}
                            onChange={handlChangedExp}
                        />
                        <TextField
                            fullWidth
                            label="secteur"
                            name="secteur"
                            id="secteur"
                            type="text"
                            value={exp.secteur}
                            onChange={handlChangedExp}
                        />
                        <InputLabel for="datedebutExp">Date de debut</InputLabel>
                        <Input
                            fullWidth
                            id="datedebutExp"
                            onChange={handlChangedExp}
                            name="datedebutExp"
                            type="Date"
                            selected={startDate}
                            dateFormat="yyyy-MM-dd"
                        />
                        <InputLabel for="datefinExp">Date de fin</InputLabel>
                        <Input
                            fullWidth
                            id="datefinExp"
                            onChange={handlChangedExp}
                            name="datefinExp"
                            type="Date"
                            selected={startDate}
                            dateFormat="yyyy-MM-dd"
                        />
                        <TextField
                            fullWidth
                            label="mission"
                            name="mission"
                            id="mission"
                            type="text"
                            value={exp.mission}
                            onChange={handlChangedExp}
                        />
                        <TextField
                            fullWidth
                            label="cadretechnique"
                            name="cadretechnique"
                            id="cadretechnique"
                            type="text"
                            value={exp.cadretechnique}
                            onChange={handlChangedExp}
                        />
                        <TextField
                            fullWidth
                            label="methodologie"
                            name="methodologie"
                            id="methodologie"
                            type="text"
                            value={exp.methodologie}
                            onChange={handlChangedExp}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ajouterExp}>Ajouter</Button>
                        <Button onClick={handleClose}>Annuler</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={opengen} onClose={handleCloseGen}>
                    <DialogTitle>Ajouter l'audio de l'entretien</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Choisir fichier audio </DialogContentText>
                        <br></br>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <input
                                fullWidth
                                label="link"
                                name="link"
                                id="link"
                                type="file"
                                accept=".mp3, .wav"  
                                value={gen.link}
                                onChange={handleFileChange}
                            />
                        </Grid>
                        <image
                            type="audio"
                            src="https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/af52a99b-88c0-4638-b120-d46e142d06d3/audio/500344fb-2e2b-48af-be86-af6ac341a6da/default_tc.mp3"
                        ></image>
                    </DialogContent>
                    <DialogActions>
                       
                        <Button onClick={ajoutergen}>Envoyer</Button>
                        <Button onClick={handleCloseGen}>Annuler</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openlink} onClose={handleCloselink}>
                    <Card sx={{ display: 'flex' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <Typography component="div" variant="h5">
                                Live from Drive link : {gen.link}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                    Mac Miller
                                </Typography>
                            </CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                <IconButton aria-label="previous">
                                    {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
                                </IconButton>
                                <IconButton aria-label="play/pause">
                                    <PlayArrowIcon sx={{ height: 38, width: 38 }} />
                                </IconButton>
                                <IconButton aria-label="next">
                                    {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
                                </IconButton>
                            </Box>
                        </Box>
                        <CardMedia component="img" sx={{ width: 151 }} image="/audio.png" alt="Live from Drive link :" />
                    </Card>
                </Dialog>

                <Accordion expanded={expanded === data.id} onChange={handleChange(data.id)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography gutterBottom variant="h4" component="div">Détails du Fiche N : {data.id}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography gutterBottom variant="h4" component="div">Recruteur :</Typography>
                        <Typography>    {data.recruteur}</Typography>
                                                
                        <Typography gutterBottom variant="h4" component="div">Date d'entretien :</Typography>
                        <Typography>-{data.date_entrtien}</Typography>
                   
                        <Typography gutterBottom variant="h4" component="div">Disponibilité :</Typography>
                        <Typography>-{data.disponibilite}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Date de disponibilité:</Typography>
                        <Typography>-{data.date_dispo}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Le candidat est en poste ? </Typography>
                        <Typography>-{data.est_en_poste}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Status du profil candidat : </Typography>
                        <Typography>-{data.status}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Le candidat est mobile ?</Typography>
                        <Typography>-{data.mobile}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Zone de mobilité :</Typography>
                        <Typography>-{data.zone_mobilite}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Avantages souhaités :</Typography>
                        <Typography>--{data.avantages_souh}</Typography>
                        
                     
                        <Typography gutterBottom variant="h4" component="div">Motivations et attentes du candidat:</Typography>
                        <Typography>-{data.motiv_attente}</Typography>
                        {/* <Typography gutterBottom variant="h5" component="div">motiv_changt :</Typography>
                        <Typography>-{data.motiv_changt}</Typography> */}
                        <Typography gutterBottom variant="h4" component="div">Niveau d'avancement des processus:</Typography>
                        <Typography>-{data.nv_avancement}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Points Incontournables :</Typography>
                        <Typography>-{data.points_inco}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Processus de recrutement :</Typography>
                        <Typography>-{data.autreprocessus }</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration brute active  :</Typography>
                        <Typography>-{data.remun_brute_act}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration brute souhaités :</Typography>
                        <Typography>-{data.remun_brute_souh}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration fixe  active:</Typography>
                        <Typography>-{data.remun_fixe_act}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration fixe souhaités:</Typography>
                        <Typography>-{data.remun_fixe_souh}</Typography>
                        <Typography gutterBottom variant="h4" component="div"> Hard skills :</Typography>
                        <Typography>-{data.hard_skills}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Soft skills :</Typography>
                        <Typography>-{data.soft_skills}</Typography>

                        
                      

                        <Link to={'/vue_recruteur_ModifierficheDentretien/' + data.profil_id + '/' + data.offre_id + '/' + data.id}>
                            Modifier La fiche
                        </Link>
                        <Typography gutterBottom variant="h4" component="div">L'audio de l'entretien  :</Typography>

                        {/* <audio controls >
                           <source src={audioSource} type="audio/mp3" />
                        </audio> */}
                    <div>
                         {(audioSource && (
                            <ReactAudioPlayer src={audioSource} controls />

                         )) ||     <Typography gutterBottom variant="h5" component="div">pas d'audio disponibile</Typography>
                        }
                         <br></br>
                         { (role==='Admin' && audioSource) && (
                              <Button onClick={supprimerAudio} variant="contained" color="primary">
                              supprimer l'audio
                          </Button>
                            )
 }
                    </div>
                        {/* {audioSource ? (
                          <audio controls>
                            <source src={audioSource} type="audio/mp3" />
                           </audio>
                       ) : (
                       <audio controls>
                        <source src={audioSource} type="audio/mp3" />
                        </audio>
                    //    )} */}

                    </AccordionDetails>
                </Accordion>
                {exper.map((row) => (
                    <Accordion expanded={expanded === row.entreprise} onChange={handleChange(row.entreprise)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Expériance N: {row.id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Entreprise : {row.entreprise}</Typography>
                            <Typography>secteur : {row.secteur}</Typography>
                            <Typography>datefin : {row.datefin}</Typography>
                            <Typography>datedebut : {row.datedebut}</Typography>
                            <Typography>mission : {row.mission}</Typography>

                            <Typography>cadretechnique : </Typography>
                            <Typography>-{row.cadretechnique}</Typography>
                            <Typography>methodologies :</Typography>
                            <Typography>-{row.methodologies}</Typography>
                            <Typography>Date Création :</Typography>
                            <Typography>-{row.created_at}</Typography>
                            <Link to={'/vue_recruteur_ModifierExperience/' + row.id}>Modifier Expériance</Link>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </MainCard>
        </div>
    );
}
if(role==='Entreprise'){


    return (
        <div>
            <MainCard title="Détails fiche D'entretien ">
           
             

   
                <Accordion expanded={expanded === data.id} onChange={handleChange(data.id)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography gutterBottom variant="h4" component="div">Détails du Fiche N : {data.id}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography gutterBottom variant="h4" component="div">Recruteur :</Typography>
                        <Typography>    {data.recruteur}</Typography>
                                                
                        <Typography gutterBottom variant="h4" component="div">Date d'entretien :</Typography>
                        <Typography>-{data.date_entrtien}</Typography>
                   
                        <Typography gutterBottom variant="h4" component="div">Disponibilité :</Typography>
                        <Typography>-{data.disponibilite}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Date de disponibilité:</Typography>
                        <Typography>-{data.date_dispo}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Le candidat est en poste ? </Typography>
                        <Typography>-{data.est_en_poste}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Status du profil candidat : </Typography>
                        <Typography>-{data.status}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Le candidat est mobile ?</Typography>
                        <Typography>-{data.mobile}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Zone de mobilité :</Typography>
                        <Typography>-{data.zone_mobilite}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Avantages souhaités :</Typography>
                        <Typography>--{data.avantages_souh}</Typography>
                        
                     
                        <Typography gutterBottom variant="h4" component="div">Motivations et attentes du candidat:</Typography>
                        <Typography>-{data.motiv_attente}</Typography>
                        {/* <Typography gutterBottom variant="h5" component="div">motiv_changt :</Typography>
                        <Typography>-{data.motiv_changt}</Typography> */}
                        <Typography gutterBottom variant="h4" component="div">Niveau d'avancement des processus:</Typography>
                        <Typography>-{data.nv_avancement}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Points Incontournables :</Typography>
                        <Typography>-{data.points_inco}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Processus de recrutement :</Typography>
                        <Typography>-{data.autreprocessus }</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration brute active  :</Typography>
                        <Typography>-{data.remun_brute_act}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration brute souhaités :</Typography>
                        <Typography>-{data.remun_brute_souh}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration fixe  active:</Typography>
                        <Typography>-{data.remun_fixe_act}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Rémuneration fixe souhaités:</Typography>
                        <Typography>-{data.remun_fixe_souh}</Typography>
                        <Typography gutterBottom variant="h4" component="div"> Hard skills :</Typography>
                        <Typography>-{data.hard_skills}</Typography>
                        <Typography gutterBottom variant="h4" component="div">Soft skills :</Typography>
                        <Typography>-{data.soft_skills}</Typography>

                        
                      

                        <Link to={'/vue_recruteur_ModifierficheDentretien/' + data.profil_id + '/' + data.offre_id + '/' + data.id}>
                            Modifier La fiche
                        </Link>
                        <Typography gutterBottom variant="h4" component="div">L'audio de l'entretien  :</Typography>

                        {/* <audio controls >
                           <source src={audioSource} type="audio/mp3" />
                        </audio> */}
                    <div>
                         {(audioSource && (
                            <ReactAudioPlayer src={audioSource} controls />

                         )) ||     <Typography gutterBottom variant="h5" component="div">pas d'audio disponibile</Typography>
                        }
                         <br></br>
          
                    </div>
                        {/* {audioSource ? (
                          <audio controls>
                            <source src={audioSource} type="audio/mp3" />
                           </audio>
                       ) : (
                       <audio controls>
                        <source src={audioSource} type="audio/mp3" />
                        </audio>
                    //    )} */}

                    </AccordionDetails>
                </Accordion>
                {exper.map((row) => (
                    <Accordion expanded={expanded === row.entreprise} onChange={handleChange(row.entreprise)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Expériance N: {row.id}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Entreprise : {row.entreprise}</Typography>
                            <Typography>secteur : {row.secteur}</Typography>
                            <Typography>datefin : {row.datefin}</Typography>
                            <Typography>datedebut : {row.datedebut}</Typography>
                            <Typography>mission : {row.mission}</Typography>

                            <Typography>cadretechnique : </Typography>
                            <Typography>-{row.cadretechnique}</Typography>
                            <Typography>methodologies :</Typography>
                            <Typography>-{row.methodologies}</Typography>
                            <Typography>Date Création :</Typography>
                            <Typography>-{row.created_at}</Typography>
                            <Link to={'/vue_recruteur_ModifierExperience/' + row.id}>Modifier Expériance</Link>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </MainCard>
        </div>
    );

}
}
