import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';

import { useHistory, useParams } from 'react-router-dom';
import AuthUser from 'AuthUser';
import { Navigate, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Card,
     CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Create a CSS-in-JS styles using makeStyles
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
      },
      avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: theme.spacing(2),
      },
    }));
  
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addEntreprise= () => {
///profil
const [data, setData] = useState({
    nom: '',
    Description: '',
    adresse: '',
    pays: '',
    email:'',
    contact:'',
    status:'',
    photo:''
});
//const history = useHistory();
const handlChanged = (e) => {
    // if ( e.target.name === 'email' || e.target.name === 'nom') {
    //     if(e.target.value.trim() === '') 
    //      {
    //         alert('Email  and name are required');
    //      }
    // }
    console.log('test');
     if (e.target.name === 'photo') {
        setData({ ...data, photo: e.target.files[0]});
        console.log(data.photo);
    } 
    else {
        setData({ ...data, [e.target.name]: e.target.value });
    }
};


const{ email,role} = AuthUser(); 



///l'id de l'entreprise
const classes = useStyles();
const history=useNavigate();
///to load data from databaseco


///to update the profil
const submitForm = (e) => {
    console.log(data);
    console.log(data.photo);

    //tester les champs obligatoire
if(email!='' && role !='')
{
    if(data.nom=='' || data.email=='' ||  data.adresse=='')
    {
        alert('Email, nom et adresse sont obligatoire');
    }
    else{  
       
        const formData = new FormData();
        formData.append('photo',data.photo);
        formData.append('nom',data.nom);
        formData.append('Description',data.Description);
        formData.append('adresse',data.adresse);
        formData.append('pays',data.pays);
        formData.append('email',data.email);
        formData.append('contact',data.contact);
        formData.append('info',data.info);
        console.log(formData.get('photo'));
    //    // handleCloseGen();
    //    // alert("test");
    //     // axios.post('https://sourcing.openmind-its.com/api/audio/upload/'+id, formData, {
    //     //     headers: {
    //     //       'Content-Type': 'multipart/form-data'
    //     //     }
    //     //   })
    // axios
    //  .post('https://sourcing.openmind-its.com/api/entreprise/addEntreprise', { 
    //         nom:data.nom,
    //         Description:data.Description,
    //         adresse:data.adresse,
    //         pays:data.pays,
    //         email:data.email,
    //         contact:data.contact,
    //         photo:data.photo,
    //         info:data.info
    //     })
    axios
    .post('https://sourcing.openmind-its.com/api/entreprise/addEntreprise',formData, { 
        headers: {
            'Content-Type': 'multipart/form-data'
          }
       })
        .then(function (response) {
             //console.log(response.headers);
            alert('Entreprise bien ajoutee');
            history('/vue_recruteur_listEntreprise');
        })
        .catch(function (error) {
            alert('L\'Entreprise existe deja');
        });
    }
}
else{
    alert('une connexion est requise');
    history('/login');
}
};
    return(
        <>

<MainCard title="Ajouter Entreprise">
                <form noValidate>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                    
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="Nom" name="nom" type="text" value={data.nom} onChange={handlChanged} required/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="Informations Entreprise" name="Description" value={data.Description} type="text" onChange={handlChanged} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="adresse" name="adresse"  type="text"  value={data.adresse} onChange={handlChanged} required/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="pays" name="pays"  type="text"  value={data.pays} onChange={handlChanged} />
                        </Grid> 
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                           <TextField fullWidth label="email" name="email"  type="text"  value={data.email} onChange={handlChanged} required/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                           <TextField fullWidth label="contact" name="contact"  type="text"  value={data.contact} onChange={handlChanged} />
                        </Grid>
                        <Grid item lg={12} md={6} sm={6} xs={12}>
                           <TextField fullWidth label="Informations Complémentaire" name="info"  type="text"  value={data.info} onChange={handlChanged} />
                        </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label htmlFor="photo-upload" className="custom-file-upload">
                                 Choisir Photo :
                                 
                                 </label>
                                 <br />
                                 <br />
                           <input type="file" accept=".jpg, .jpeg, .png"  onChange={handlChanged} name="photo" />
                         
                           {data.photo && <p>Selected file: {data.photo.name}</p>}
                    </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                       
                                    >
                                        + Ajouter
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </form>
    
    
               </MainCard>
    
    
    
        </>
    
    )

};
export default Recruteur_addEntreprise;
