import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { Button, MenuItem, Grid, Select, FormControl, InputLabel, Input, TextField, FormHelperText } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import DownloadLink from 'react-download-link';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//table
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    //
    // ==============================|| SAMPLE PAGE ||============================== //

    let history = useNavigate();
    const { id } = useParams();
    //console.log(id);

    const [exper, setexper] = useState([]);
    useEffect(() => {
        if( email!='' && (role==='Recruteur' || role==='Admin'))
        {  
         load();
        }
        else history('/login');
    }, []);
    const load = (e) => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/textAndio/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                // setData(json.fiche[0]);
                setexper(json);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addProfile/' + id);
    };

    return (
        <MainCard title="Text de l'audio">
            <Typography variant="body2">{exper.texte}</Typography>
        </MainCard>
    );
}
