import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem,
    label
} from '@mui/material';
//table

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table
import * as React from 'react';
import AuthUser from 'AuthUser';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import { ContactSupportOutlined, FaceRetouchingOffSharp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
//session
import { ReactSession } from 'react-client-session';
// ==============================|| SAMPLE PAGE ||============================== //
const Espace_recruteur = () => {
    let history = useNavigate();
    //console.log(senddata);
    const [candidats, setCandidats] = useState([]);
    const [origin, setOrigin] = useState([]);
    const { name, email,role } = AuthUser();
    useEffect(() => {
        if (name != undefined) {
            load();
        } else {
            history('/login');
        }
    }, []);
    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Candidats';
                const resp = await fetch(requrl);
                const json = await resp.json();
                //  console.log(json);
                setCandidats(json);
                setOrigin(json);
                // console.log('******************');
                // console.log(candidats);
            } catch (error) {
                //console.error('error');
            }
        }
        f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addCandidat');
    };

    //supprimer
    const changerStatus = async (id) => {
        axios
            .get('https://sourcing.openmind-its.com/api/updateStatusCandidat/' + id)
            .then(function (response) {
                load();
            })
            .catch(function (error) {});
    };
    const reload = () => {
        load();
    };
    ///supprimer
    const deleteCandidat =(id) => {
        if(role==='Admin')
        {
            axios
                    .get('https://sourcing.openmind-its.com/api/candidats/delete/'+id,{
                    })
                    .then(function (response) {
                        alert("Le candidat est bien supprimé.");
                        history('/vue_recruteur_listCandidats');
                       // console.log(response);
                    })
                    .catch(function (error) {
                     alert("Echec de suppression");
                        history('/vue_recruteur_listCandidats');
    
                    });
            
        }
    }
    //Search
    const handleSearch = (event) => {
        if (event.target.value == 'active') {
            setCandidats(origin.filter((cand) => cand.status.includes('active')));
        } else if (event.target.value == 'archivé') {
            setCandidats(origin.filter((cand) => cand.status.includes('archivée')));
        } else if (event.target.value == 'invalid') {
            setCandidats(origin.filter((cand) => cand.status.includes('invalide')));
        } else {
            load();
            setCandidats(candidats);
        }

        // setSearch(event.target.value);
    };
if(role==='Admin')
{
    return (
        <MainCard title="Liste Candidats">
            <Button onClick={redirection} variant="contained" color="primary">
                Ajouter Candidat
            </Button>
            <Box mt={1} sx={{ float: 'right', width: '14rem' }}>
                <FormControl fullWidth>
                    <InputLabel id="status">Filtrer par Status </InputLabel>
                    <Select labelId="status" label="Filtrer par Status" id="status" name="status" onChange={handleSearch}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="archivé">Archivé</MenuItem>
                        <MenuItem value="invalid">Invalide</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Id</TableCell>
                                                    <TableCell align="right">Nom</TableCell>
                                                    <TableCell align="right">Prénom</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right">Date de Naissance</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {candidats.length != 0 ? (
                                                    candidats.map((row) => (
                                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="right">{row.nom}</TableCell>
                                                            <TableCell align="right">{row.prenom}</TableCell>
                                                            <TableCell align="right">{row.status}</TableCell>
                                                            <TableCell align="right">{row.datenaissance}</TableCell>
                                                            <TableCell align="right">
                                                                {' '}
                                                                <Link
                                                                    to={'/vue_recruteur_detailCandidat/' + row.id}
                                                                    fullWidth
                                                                    size="large"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                >
                                                                    Détails
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {' '}
                                                                <AnimateButton>
                                                                    <Button
                                                                        fullWidth
                                                                        onClick={() => changerStatus(row.id)}
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        {row.status == 'active' ? 'Archiver' : 'Activer'}
                                                                    </Button>
                                                                </AnimateButton>
                                                               </TableCell>
                                                        <TableCell>
                                                                <AnimateButton>
                                                                    <Button
                                                                        
                                                                        onClick={()=>deleteCandidat(row.id)}
                                                                        // onClick={redirectionGen} 
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        supprimer
                                                                    </Button>
                                                                </AnimateButton>
                                                        </TableCell>
                                                    </TableRow>
                                                           
        
                                                    ))
                                                ) : (
                                                    <TableRow key="1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <Box mt={2} sx={{ textAlign: 'right' }}>
                                                            {'Aucun Candidat avec ce Status '}
                                                        </Box>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
}
if(role==undefined || role!='') {
    return (
        <MainCard title="Liste Candidats">
            <Button onClick={redirection} variant="contained" color="primary">
                Ajouter Candidat
            </Button>
            <Box mt={1} sx={{ float: 'right', width: '14rem' }}>
                <FormControl fullWidth>
                    <InputLabel id="status">Filtrer par Status </InputLabel>
                    <Select labelId="status" label="Filtrer par Status" id="status" name="status" onChange={handleSearch}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="archivé">Archivé</MenuItem>
                        <MenuItem value="invalid">Invalide</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Id</TableCell>
                                                    <TableCell align="right">Nom</TableCell>
                                                    <TableCell align="right">Prénom</TableCell>                                                    <TableCell align="right">status</TableCell>
                                                    <TableCell align="right">Date de Naissance</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {candidats.length != 0 ? (
                                                    candidats.map((row) => (
                                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="right">{row.nom}</TableCell>
                                                            <TableCell align="right">{row.prenom}</TableCell>
                                                            <TableCell align="right">{row.status}</TableCell>
                                                            <TableCell align="right">{row.datenaissance}</TableCell>
                                                            <TableCell align="right">
                                                                {' '}
                                                                <Link
                                                                    to={'/vue_recruteur_detailCandidat/' + row.id}
                                                                    fullWidth
                                                                    size="large"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                >
                                                                    Détails
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {' '}
                                                                <AnimateButton>
                                                                    <Button
                                                                        fullWidth
                                                                        onClick={() => changerStatus(row.id)}
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        {row.status == 'active' ? 'Archiver' : 'Activer'}
                                                                    </Button>
                                                                </AnimateButton>

                                                        
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow key="1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <Box mt={2} sx={{ textAlign: 'right' }}>
                                                            {'Aucun Candidat avec ce Status '}
                                                        </Box>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
}
};
export default Espace_recruteur;
