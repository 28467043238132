import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';

import { useHistory, useParams } from 'react-router-dom';
import AuthUser from 'AuthUser';
import { Navigate, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import {
    Avatar,
    Box,
    Button,
    Checkbox,
    Card,
     CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Create a CSS-in-JS styles using makeStyles
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
      },
      avatar: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: theme.spacing(2),
      },
    }));
  
// ==============================|| SAMPLE PAGE ||============================== //
const Entreprise_profile= () => {
///profil
const [data, setData] = useState({
    nom: '',
    Description: '',
    adresse: '',
    pays: '',
    email:'',
    contact:'',
    status:'',
    photo:'',
    info_complementaire:''
});
//const history = useHistory();
const handlChanged = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
};


const{ email} = AuthUser(); 

const{id}=useParams();

///l'id de l'entreprise
const classes = useStyles();

///to load data from databaseco
const load = () => {
    async function f() {
        try {
           
            const requrl = 'https://sourcing.openmind-its.com/api/entreprise/'+id;
            const resp = await fetch(requrl);
            const json = await resp.json();
            setData(json);
        } catch (error) {
            console.error('error');
        }
    }
    f();
};
let history = useNavigate(); //use for navigate previons
useEffect(() => {
    if (email != '') {
        load();
    } 
    else {
        history('/login');
    }
}, []);


///to update the profil
const submitForm = (e) => {
    console.log(data);
    axios
     .post('https://sourcing.openmind-its.com/api/entreprise/updateEntreprise/'+id, { 
            nom:data.nom,
            Description:data.Description,
            adresse:data.adresse,
            pays:data.pays,
            email:data.email,
            contact:data.contact,
            photo:data.photo,
            info_complementaire:data.info_complementaire
        })
        .then(function (response) {
            //  console.log(response.data);
            alert('Profile bien Modifier');

            history('/vue_entreprise_profil/'+id);
        })
        .catch(function (error) {
            alert('Echec de modification'+error);
        });
};
    return(
        <>
<Card>
      <CardContent>
        <Grid container className={classes.root} >
          <Grid item >
            <Avatar className={classes.avatar} alt="Profile Picture" src={data.photo ? `https://sourcing.openmind-its.com/images/${data.photo}` : ''} />
          </Grid>
          <Grid item>
            <Typography variant="h6">{data.nom}</Typography>
            <Typography variant="body1">{data.Description}</Typography>
          </Grid>
          
        </Grid>
      </CardContent>
    </Card>
<MainCard title="Informations Entreprise">
                <form noValidate>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                    
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="nom" name="nom" type="text" value={data.nom} onChange={handlChanged} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="Informations Entreprise" name="Description" value={data.Description} type="text" onChange={handlChanged} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="adresse" name="adresse"  type="text"  value={data.adresse} onChange={handlChanged} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField fullWidth label="pays" name="pays"  type="text"  value={data.pays} onChange={handlChanged} />
                        </Grid> 
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                           <TextField fullWidth label="email" name="email"  type="text"  value={data.email} onChange={handlChanged} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                           <TextField fullWidth label="contact" name="contact"  type="text"  value={data.contact} onChange={handlChanged} />
                        </Grid>
                       <Grid item lg={12} md={6} sm={6} xs={12}>
                           <TextField fullWidth label="Informations Complementaires" name="info_complementaire"  type="text"  value={data.info_complementaire} onChange={handlChanged} />
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ mt: 2 }}>
                                <AnimateButton>
                                    <Button
                                        disableElevation
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        onClick={submitForm}
                                       
                                    >
                                        + Modifier
                                    </Button>
                                </AnimateButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            
        </form>
    
    
               </MainCard>
    
    
    
        </>
    
    )

};
export default Entreprise_profile;
