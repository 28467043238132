// assets
import { IconDashboard } from '@tabler/icons';
import AuthUser from 'AuthUser';
// constant
const icons = { IconDashboard };
const { name, role } = AuthUser();
// ==============================|| DASHBOARD MENU ITEMS ||============================== //
let dashboard =''
if (name != ''){
    dashboard = {
        id: 'role',
        title: 'Bienvenue',
        type: 'group',
        children: [
            {
                id: 'name',
                title: 'Name : ' + name,
                type: 'item',
                url: '/profile',
                breadcrumbs: false
            },
            {
                id: 'role',
                title: 'Role : ' + role,
                type: 'item',
                url: '/profile',

                breadcrumbs: false
            }
        ]
    }
} else {
    dashboard  = {
        id: 'role',
        title: '',
        type: 'group',
        children: []
    }
};

export default dashboard;
