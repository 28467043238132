import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import AuthUser from 'AuthUser';
import { Button, Grid, FormControl, InputLabel, Select, MenuItem, Box, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import DownloadLink from 'react-download-link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
//session
import { ReactSession } from 'react-client-session';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import Divider from '@mui/material/Divider';
//table
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    //
    // ==============================|| SAMPLE PAGE ||============================== //
    let history = useNavigate();
    const {id} = useParams();
    //console.log(id);
    const [data, setData] = useState({
        id: '',
        nom: '',
        prenom: '',
        dateNaissance: new Date(),
        ville: '',
        pays: '',
        email: '',
        cv: '',
        titre: '',
        etatduprofil: '',
        mobilite: '',
        active: '',
        pretentionSalarial: '',
        show: 'false',
        nombreprofils: '',
        status: '',
        audioentretien: ''
    });
    /*{
        id: '',
        type: '',
        bonjour: '',
        header: '',
        body: '',
        footer: '',
        created_at: '',
        updated_at: ''
    } */
    //email
    const [em, setEmail] = useState({
        id: '',
        type: '',
        bonjour: '',
        header: '',
        body: '',
        footer: '',
        created_at: '',
        updated_at: ''
    });
    //envoi mail
    const handlChanged = (e) => {
        setEmail({ ...em, [e.target.name]: e.target.value });
        setOpen(true);
    };
    const { name, email ,role} = AuthUser();
    //
    const [profile, setProfile] = useState([]);
    useEffect(() => {
        if (name != '' || role!='') {
            load();
        } else {
            history('/login');
        }
    }, []);

    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Candidats/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();

                sete(json.email);
                setData(json.candidatData);
                setProfile(json.profils);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addProfile/' + id);
    };

    //email
    const [e, sete] = useState([]);
    const [c, setc] = useState({
        footer: '',
        label: '',
        body: '',
        header: '',
        bonjour: 'Merci de selectionner un Model pour Votre Email',
        type: '',
        id: ''
    });
    const changeEmail = (event) => {
        // console.log(event.target.value);
        e.forEach((ema) => {
            if (ema.type == event.target.value) {
                setc(ema);
            }
        });
    };
    const handleSearch = (event) => {
        if (event.target.value != '') {
            setEmail(em.filter((tag) => tag.titre.toLowerCase().includes(event.target.value.toLowerCase())));
        } else {
            //console.log(dectionnarecopy);
            //setEmail(dectionnarecopy);
        }
    };
   
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    
    //***************************************************** */
    const changelab = (e) => {
        setc({ ...c, [e.target.name]: e.target.value });
    };

    const handleClose = () => {
        setOpen(false);
    };
    const Envoyer = () => {
        console.log(c.footer);
        axios
            .post('https://sourcing.openmind-its.com/api/sendmail', {
                body: c.body,
                bonjour: c.bonjour,
                footer: c.footer,
                header: c.header,
                type: c.type,
                email: data.email
            })
            .then((res) => {
                alert('bien regénere');
                handleClose();
            })
            .catch((e) => {
                // console.log('error', e);
                alert('email non sende');
            });
    };
    //end email
    const redirectionEmail = (e) => {
        // history('/vue_entreprise_offre');
        setOpen(true);
    };
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    const regenerertag = (e, id) => {};
    const listOffres = (e, id) => {
        history('/vue_recruteur_ProfileListOffres/' + id);
    };
    function downloadUrlCv(cv) {
        const filepath = cv;
        const filename = filepath.substring(filepath.lastIndexOf('/') + 1);
        const downloadUrl = `https://sourcing.openmind-its.com/cvs/${filename}`;
        return downloadUrl;
    }
    

    function downloadUrlDossier(dossier) {
        if (!dossier) {
            return null;
        }

        const filename = dossier.substring(dossier.lastIndexOf('/') + 1);
        const downloadUrl = `https://sourcing.openmind-its.com/competences/${filename}`;

        console.log(downloadUrl);
        return downloadUrl;
    }
    
///Cas d'un autre role
if(role!='Entreprise')
{
    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Envoyer Email
                        </Typography>
                        <Button autoFocus color="inherit" onClick={Envoyer}>
                            Envoyer
                        </Button>
                    </Toolbar>
                </AppBar>
                <Grid container mt={3} spacing={2} alignItems="center" justifyContent="center">
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="type">Type de Message </InputLabel>
                            <Select onChange={changeEmail} labelId="type" label="Type de Message" id="type" name="type">
                                <MenuItem value="Prise de rendez-vous sans CV">Prise de rendez-vous sans CV</MenuItem>
                                <MenuItem value="Ni Prise de rendez-vous Ni CV">Ni Prise de rendez-vous Ni CV</MenuItem>
                                <MenuItem value="un candidat qui rate son rendez-vous">un candidat qui rate son rendez-vous</MenuItem>
                                <MenuItem value="Feedback sans nouvelles proposition">Feedback sans nouvelles proposition</MenuItem>
                                <MenuItem value="nouvelle proposition après l'entretien">nouvelle proposition après l'entretien</MenuItem>
                                <MenuItem value="envoi du cv sans Prise de rendez-vous">envoi du cv sans Prise de rendez-vous</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10} mr={1}>
                        <TextField
                            multiline
                            fullWidth
                            label="bonjour"
                            name="bonjour"
                            id="bonjour"
                            type="text"
                            onChange={changelab}
                            value={c.bonjour}
                        />
                        <TextField
                            multiline
                            fullWidth
                            label="header"
                            name="header"
                            id="header"
                            type="text"
                            onChange={changelab}
                            value={c.header}
                        />
                        <TextField multiline fullWidth label="body" name="body" id="body" type="text" onChange={changelab} value={c.body} />
                        <TextField
                            multiline
                            fullWidth
                            label="footer"
                            name="footer"
                            id="footer"
                            type="text"
                            onChange={changelab}
                            value={c.footer}
                        />
                    </Grid>
                </Grid>
            </Dialog>

            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Button onClick={redirection} variant="contained" color="primary">
                        Ajouter Profile
                    </Button>
                </Grid>
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Button onClick={handlChanged} variant="contained" color="primary">
                        envoi Email
                    </Button>
                </Grid>
            </Grid>
            <MainCard title="Détails Candidat">
                <Accordion expanded={expanded === data.id} onChange={handleChange(data.id)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>
                        Détails du candidat : {data.nom} {data.prenom}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h4" >Nom Complét :</Typography>
                        <Typography>
                            -{data.nom} {data.prenom}
                        </Typography>
                        <Typography variant="h4" >Email :</Typography>
                        <Typography>--{data.email}</Typography>
                        <Typography variant="h4" >Date de Naissance :</Typography>
                        <Typography>-{data.datenaissance}</Typography>
                        <Typography variant="h4" > Ville :</Typography>
                        <Typography>-{data.ville}</Typography>

                        <Typography variant="h4" >Pays :</Typography>
                        <Typography>-{data.pays}</Typography>
                        <Typography variant="h4" >Etat du profile :</Typography>
                        <Typography>-{data.etatduprofil}</Typography>
                        <Typography variant="h4" >Audio Entretien :</Typography>
                        <Typography>-{data.audioentretien}</Typography>

                        <Typography variant="h4" >Mobilité :</Typography>
                        <Typography>-{data.mobilite}</Typography>
                        <Typography variant="h4" > Status :</Typography>
                        <Typography>-{data.status}</Typography>
                        <Typography variant="h4" >Nombre de profils :</Typography>
                        <Typography>-{data.nombreprofils}</Typography>
                        <Link to={'/vue_recruteur_modifierCandidat/' + data.id}>Modifier Candidat</Link>
                    </AccordionDetails>
                </Accordion>
                {profile.map((row) => (
                    <Accordion expanded={expanded === row.titreduprofil} onChange={handleChange(row.titreduprofil)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Profil : {row.titreduprofil}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h4" >Titre du profil :</Typography>
                            <Typography>-{row.titreduprofil}</Typography>
                            <Typography variant="h4" >Type du profil :</Typography>
                            <Typography>-{row.typeduprofil}</Typography>
                            <Typography variant="h4" >CV :</Typography>
                            <Typography>
                                <a href={downloadUrlCv(row.cv)} target="_blank" rel="noreferrer" download>
                                    Télécharger CV
                                </a>
                            </Typography>

                            {downloadUrlDossier(row.dossiercompetence) !== null && (
                                <div>
                                    <Typography variant="h4" >Dossier Du Compétence :</Typography>
                                    <Typography>
                                        <a href={downloadUrlDossier(row.dossiercompetence)} target="_blank" rel="noreferrer">
                                            Télécharger Dossier de Compétence
                                        </a>
                                    </Typography>
                                </div>
                            )}

                            <Typography variant="h4" >Prétentions Salariales :</Typography>
                            <Typography>-{row.pretentionssalariales}</Typography>
                            <Typography variant="h4" >Status :</Typography>
                            <Typography>-{row.status}</Typography>
                            <Link to={'/vue_recruteur_modifierProfile/' + row.id}>Modifier Profil</Link>
                            <Button onClick={(e) => regenerertag(e, row.id)} variant="contained" color="primary">
                                régénérer tages
                            </Button>
                            <Button onClick={(e) => listOffres(e, row.id)} variant="contained" color="primary">
                                Liste Offres
                            </Button>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </MainCard>
        </div>
    );
    }

 ///Cas d'une entreprise
if(role==='Entreprise'){

    return (
        <div>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Envoyer Email
                        </Typography>
                        <Button autoFocus color="inherit" onClick={Envoyer}>
                            Envoyer
                        </Button>
                    </Toolbar>
                </AppBar>
                <Grid container mt={3} spacing={2} alignItems="center" justifyContent="center">
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <FormControl fullWidth>
                            <InputLabel id="type">Type de Message </InputLabel>
                            <Select onChange={changeEmail} labelId="type" label="Type de Message" id="type" name="type">
                                <MenuItem value="Prise de rendez-vous sans CV">Prise de rendez-vous sans CV</MenuItem>
                                <MenuItem value="Ni Prise de rendez-vous Ni CV">Ni Prise de rendez-vous Ni CV</MenuItem>
                                <MenuItem value="un candidat qui rate son rendez-vous">un candidat qui rate son rendez-vous</MenuItem>
                                <MenuItem value="Feedback sans nouvelles proposition">Feedback sans nouvelles proposition</MenuItem>
                                <MenuItem value="nouvelle proposition après l'entretien">nouvelle proposition après l'entretien</MenuItem>
                                <MenuItem value="envoi du cv sans Prise de rendez-vous">envoi du cv sans Prise de rendez-vous</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={10} mr={1}>
                        <TextField
                            multiline
                            fullWidth
                            label="bonjour"
                            name="bonjour"
                            id="bonjour"
                            type="text"
                            onChange={changelab}
                            value={c.bonjour}
                        />
                        <TextField
                            multiline
                            fullWidth
                            label="header"
                            name="header"
                            id="header"
                            type="text"
                            onChange={changelab}
                            value={c.header}
                        />
                        <TextField multiline fullWidth label="body" name="body" id="body" type="text" onChange={changelab} value={c.body} />
                        <TextField
                            multiline
                            fullWidth
                            label="footer"
                            name="footer"
                            id="footer"
                            type="text"
                            onChange={changelab}
                            value={c.footer}
                        />
                    </Grid>
                </Grid>
            </Dialog>
{/* 
            <Grid container spacing={2} alignItems="center" justifyContent="center">
             
                <Grid item lg={2} md={2} sm={2} xs={2}>
                    <Button onClick={handlChanged} variant="contained" color="primary">
                        envoi Email
                    </Button>
                </Grid>
            </Grid>  */}

        <MainCard title="Détails Candidat">
          {profile.map((row) => (
                    <Accordion expanded={expanded === row.titreduprofil} onChange={handleChange(row.titreduprofil)}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>Profil : {row.titreduprofil}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h4" >Titre du profil :</Typography>
                            <Typography>-{row.titreduprofil}</Typography>
                            <Typography variant="h4" >Type du profil :</Typography>
                            <Typography>-{row.typeduprofil}</Typography>
                            <Typography variant="h4" >CV :</Typography>
                            <Typography>
                                <a href={downloadUrlCv(row.cv)} target="_blank" rel="noreferrer" download>
                                    Télécharger CV
                                </a>
                            </Typography>

                            {downloadUrlDossier(row.dossiercompetence) !== null && (
                                <div>
                                    <Typography variant="h4" >Dossier Du Competence :</Typography>
                                    <Typography>
                                        <a href={downloadUrlDossier(row.dossiercompetence)} target="_blank" rel="noreferrer">
                                            Télécharger Dossier de Compétence
                                        </a>
                                    </Typography>
                                </div>
                            )}

                            <Typography variant="h4">Prétentions Salariales :</Typography>
                            <Typography>-{row.pretentionssalariales}</Typography>
                            <Typography variant="h4">Status du profil:</Typography>
                            <Typography>-{row.status}</Typography>
                            {/* <Link to={'/vue_recruteur_modifierProfile/' + row.id}>Modifier Profil</Link> */}
                            {/* <Button onClick={(e) => regenerertag(e, row.id)} variant="contained" color="primary">
                                régénérer tages
                            </Button>
                            <Button onClick={(e) => listOffres(e, row.id)} variant="contained" color="primary">
                                Liste Offres
                            </Button> */}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </MainCard>
        </div>
    );


}

}
