import { useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    MenuItem,
    Input,
    InputLabel,
    Select,
    Button,
    Alert,
    FormControl,
    FormGroup,
    Checkbox,
    FormControlLabel,
    FormLabel
} from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthUser from 'AuthUser';

import { useHistory, useParams } from 'react-router-dom';
import Moment, { updateLocale } from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addCandidat = () => {
    const { idprofile, idoffr } = useParams();
    const { name, email, password, role } = AuthUser();
    let history = useNavigate(); //use for navigate previons
    const [data, setData] = useState({
        profil_id: idprofile,
        offre_id: idoffr,
        recruteur_id: '',
        est_en_poste: '',
        motiv_changt: '',
        autreprocessus: '',
        nv_avancement: '',
        mobile: '',
        zone_mobilite: '',
        remun_brute_act: '',
        remun_brute_souh: '',
        remun_fixe_act: '',
        remun_fixe_souh: '',
        avantages_souh: '',
        disponibilite: '',
        duree_preavis: '',
        date_dispo: '',
        date_entrtien: '',
        points_inco: '',
        soft_skills: '',
        motiv_attente: '',
        projet_formation: '',
        hard_skills1: '',
        hard_skills2: '',
        hard_skills3: '',
        recruteur: ''
    });

    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
  
    const submitForm = (e) => {
        if( data.est_en_poste=== ''|| data.date_entrtien=== ''|| data.recruteur=== '')
        {
            alert("Le nom du recruteur ,la date d'entretien et le candidat est-il en poste  sont des champs obligatoires!!!")
        }
    
    else{
        const formatDate = Moment(data.dateNaissance);
        const formatDatedebut = Moment(data.datedebut);
        const formatDatefin = Moment(data.datefin);
        // alert(formatDate.format('YYYY-MM-DD'));

        console.log(data);
    axios
            .post('https://sourcing.openmind-its.com/api/addfich', {
                profil_id: data.profil_id,
                offre_id: data.offre_id,
                recruteur_id: data.recruteur_id,
                est_en_poste: data.est_en_poste,
                motiv_changt: data.motiv_changt,
                autreprocessus: data.autreprocessus,
                nv_avancement: data.nv_avancement,
                mobile: data.mobile,
                zone_mobilite: data.zone_mobilite,
                remun_brute_act: data.remun_brute_act,
                remun_brute_souh: data.remun_fixe_souh,
                remun_fixe_act: data.remun_fixe_act,
                remun_fixe_souh: data.remun_fixe_souh,
                avantages_souh: data.avantages_souh,
                disponibilite: data.disponibilite,
                duree_preavis: data.duree_preavis,
                date_dispo: data.date_dispo,
                date_entrtien: data.date_entrtien,
                points_inco: data.points_inco,
                soft_skills: data.soft_skills,
                motiv_attente: data.motiv_attente,
                projet_formation: data.projet_formation,
                hard_skills: data.hard_skills1+'\n'+ data.hard_skills2+'\n'+ data.hard_skills3,
                recruteur: data.recruteur
            })
            .then(function (response) {
                 console.log(response.data);
                //alert('Fiche bien Enregistrer');
                alert("La fiche est bien ajoutee avec l'id: "+response.data.id);
                history('/vue_recruteur_detailFicheEntretien/'+ response.data.id);
            })
            .catch(function (error) {
                //alert(response.message);
                console.log(error);
            });
    }
    };
    //add Experience
    const redirection = (e) => {
        // history('/vue_entreprise_offre');
        setOpen(true);
    };
    const [open, setOpen] = React.useState(false);
    const [exp, setExp] = useState({
        profil_id: '',
        entreprise: '',
        secteur: '',
        datefin: '',
        datedebut: '',
        mission: '',
        cadretechnique: '',
        methodologie: ''
    });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const ajouterExp = (event) => {
        console.log(exp);
        axios
            .post('https://sourcing.openmind-its.com/api/DossierCompetence', {
                profil_id: idprofile,
                entreprise: exp.entreprise,
                secteur: exp.secteur,
                datefin: exp.datefinExp,
                datedebut: exp.datedebutExp,
                mission: exp.mission,
                cadretechnique: exp.cadretechnique,
                methodologies: exp.methodologie
            })
            .then(function (response) {
                alert('Expérience Bien Ajouter');
                setOpen(false);
            })
            .catch(function (error) {
                alert('error');
            });
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    const [startDate, setStartDate] = useState(new Date());
    //upload cv
    const [cvdata, setcvData] = useState('');
    const handleChanges = (file) => {
        setcvData(file[0]);
    };

    //end upload cv
    return (
        <>
            {/* {data.show ? (
                <div></div>
            ) : (
                <Alert variant="filled" severity="error">
                    Merci d'inserer tout les champs !!
                </Alert>
            )} */}
            <MainCard title="Fiche d'entretien + Dossier de Compétence">
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="est_en_poste">Le candidat est-il en poste ?*</InputLabel>
                                                <Select
                                                    labelId="est_en_poste"
                                                    label="Le candi a-t-il en poste ?"
                                                    id="est_en_poste"
                                                    name="est_en_poste"
                                                    onChange={handlChanged}
                                                >
                                                    <MenuItem value="oui">Oui</MenuItem>
                                                    <MenuItem value="non">Non</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Quelle est sa motivation pour ce changement de poste ?"
                                                name="motiv_changt"
                                                id="motiv_changt"
                                                type="text"
                                                value={data.motiv_changt}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Le candidat a-t-il d'autres processus de recrutement en cours ?"
                                                name="autreprocessus"
                                                id="autreprocessus"
                                                type="text"
                                                value={data.autreprocessus}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Recruteur"
                                                name="recruteur"
                                                id="recruteur"
                                                type="text"
                                                value={data.recruteur}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="nv_avancement">
                                                    Quel est le niveau d'avancement de ces autres processus ? 
                                                </InputLabel>
                                                <Select
                                                    labelId="nv_avancement"
                                                    label="Quel est le niveau d'avancement de ces autres processus ? *"
                                                    id="nv_avancement"
                                                    name="nv_avancement"
                                                    onChange={handlChanged}
                                                >
                                                    <MenuItem value="peu avancé">Peu avancé</MenuItem>
                                                    <MenuItem value="avance">avancé</MenuItem>
                                                    <MenuItem value="trés avancé">trés avancé</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="mobile">Le candidat est-il mobile si nécessaire ?</InputLabel>
                                                <Select
                                                    labelId="mobile"
                                                    label="Le candidat est-il mobile si nécessaire ?"
                                                    id="mobile"
                                                    name="mobile"
                                                    onChange={handlChanged}
                                                >
                                                    <MenuItem value="oui">Oui</MenuItem>
                                                    <MenuItem value="non">Non</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Si oui précisez la zone de mobilité"
                                                name="zone_mobilite"
                                                id="zone_mobilite"
                                                type="text"
                                                value={data.zone_mobilite}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Rémunération brute actuelle
                                                *"
                                                name="remun_brute_act"
                                                id="remun_brute_act"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.remun_brute_act}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Dont rémunération fixe:"
                                                name="remun_fixe_act"
                                                id="remun_fixe_act"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.remun_fixe_act}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Rémuneration brute souhaitée:"
                                                name="remun_brute_souh"
                                                id="remun_brute_souh"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.remun_brute_souh}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Dont rémunération fixe:"
                                                name="remun_fixe_souh"
                                                id="remun_fixe_souh"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.remun_fixe_souh}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Avantages souhaités:"
                                                name="avantages_souh"
                                                id="avantages_souh"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.avantages_souh}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="disponibilite">Le candidat est-il ?</InputLabel>
                                                <Select
                                                    labelId="disponibilite"
                                                    label="Le candidat est-il ?"
                                                    id="disponibilite"
                                                    name="disponibilite"
                                                    onChange={handlChanged}
                                                >
                                                    <MenuItem value="Disponible immediatement">Disponible immédiatement</MenuItem>
                                                    <MenuItem value="Période de préavis">Période de préavis</MenuItem>
                                                    <MenuItem value="le préavie est-il négociable">le préavie est-il négociable </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Durée du préavis:"
                                                name="duree_preavis"
                                                id="duree_preavis"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.duree_preavis}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <InputLabel for="date_dispo">Date de disponibilité </InputLabel>
                                            <Input
                                                fullWidth
                                                id="date_dispo"
                                                onChange={handlChanged}
                                                name="date_dispo"
                                                type="Date"
                                                selected={startDate}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>{' '}
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <InputLabel for="date_entrtien">Le candidat a été vu en entretien visio le *</InputLabel>
                                            <Input
                                                fullWidth
                                                id="date_entrtien"
                                                onChange={handlChanged}
                                                name="date_entrtien"
                                                type="Date"
                                                selected={startDate}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Listez les points incontournables du candidat en lien avec ceux définis dans l'offre *"
                                                name="points_inco"
                                                id="points_inco"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.points_inco}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Compétences (hard skills) * (Experience 1)"
                                                name="hard_skills1"
                                                id="hard_skills1"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.hard_skills1}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Compétences (hard skills) * (Experience 2)"
                                                name="hard_skills2"
                                                id="hard_skills2"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.hard_skills2}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Compétences (hard skills) * (Experience 3)"
                                                name="hard_skills3"
                                                id="hard_skills3"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.hard_skills3}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Eléments de personnalité (soft skills) / Analyse du recruteur *"
                                                name="soft_skills"
                                                id="soft_skills"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.soft_skills}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Motivations et attentes du candidat *"
                                                name="motiv_attente"
                                                id="motiv_attente"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.motiv_attente}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Le candidat a t'il le projet de suivre une formation à court ou à moyen terme ? "
                                                name="projet_formation"
                                                id="projet_formation"
                                                type="text"
                                                onChange={handlChanged}
                                                value={data.projet_formation}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        Envoyer
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
