import { useSelector } from 'react-redux';
import { ReactSession } from 'react-client-session';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
  /*  ReactSession.setStoreType("localStorage");
    ReactSession.set("name", "NULL");
    ReactSession.set("email", "NULL");
    ReactSession.set("password", "NULL");
    ReactSession.set("role", "NULL");*/
   /*window.localStorage.setItem('name', '');
    window.localStorage.setItem('email', '');
    window.localStorage.setItem('password', '');
    window.localStorage.setItem('role', '');*/
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
