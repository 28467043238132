import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5)
}));
//table
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    //
    // ==============================|| SAMPLE PAGE ||============================== //

    let history = useNavigate();
    const { id } = useParams();
    //console.log(id);
    const [data, setData] = useState({
        titre_Offre: '',
        Status: '',
        Descriptif_Poste: '',
        Profil_Recherche: '',
        Remuneration: '',
        AvantageContext: '',
        Localisation: '',
        Points_Incontournables: ''
    });
    const [tage, setTage] = useState([]);

    const [profile, setProfile] = useState([]);
    useEffect(() => {
        load();
    }, []);
    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Offres/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                //console.log(json['offre']);
                console.log(json['offre']);
                setData(json['offre']);
                setTage(json['tags']);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    };
    //console.log(data.AvantageContext);
    const redirection = (e) => {
        history('/vue_recruteur_addProfile/' + id);
    };
    //
    const handleDelete = (chipToDelete) => () => {
        //setTage((tags) => tags.filter((tag) => tag.id !== chipToDelete.id));
        setTage(tage.filter((tag) => tag.id !== chipToDelete.id));
    };
    const [ta, setTa] = useState([]);

    const valider = (e) => {
        axios
            .put('https://sourcing.openmind-its.com/api/Tage_Offres/' + id, {
                tages: tage
            })
            .then(function (response) {
                alert('Bien Valider');
                history('/vue_recruteur_listOffres');
            })
            .catch(function (error) {
                alert(error);
            });
    };
    const changerStatu = () => {
        axios
            .get('https://sourcing.openmind-its.com/api/chagerStatuOffre/' + id)
            .then(function (response) {
                load();
                //alert('Bien');
                // history('/vue_recruteur_listOffres');
            })
            .catch(function (error) {
                alert(error);
            });
    };

    return (
        <div>
            <MainCard title="Tage Offre">
                
                <Typography gutterBottom variant="h5" component="div">
                    Titre :
                </Typography>
                <Typography> {data.titre_Offre}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Points_Incontournables :{' '}
                </Typography>
                <Typography>{data.Points_Incontournables}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Descriptif_Poste :{' '}
                </Typography>
                <Typography> {data.Descriptif_Poste}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Profil_Recherché :{' '}
                </Typography>
                <Typography> {data.Profil_Recherché}</Typography>
               
                <Typography gutterBottom variant="h5" component="div">
                    Localisation :{' '}
                </Typography>
                <Typography> {data.Localisation}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    AvantageContext :{' '}
                </Typography>
                <Typography>{data.AvantageContext}</Typography>
                <Typography gutterBottom variant="h5" component="div">
                    Status :{' '}
                </Typography>
                <Typography> {data.Status}</Typography>
            </MainCard>
        </div>
    );
}
