import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem,
    label
} from '@mui/material';
//table

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table
import * as React from 'react';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import { ContactSupportOutlined, FaceRetouchingOffSharp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AuthUser from 'AuthUser';

// ==============================|| SAMPLE PAGE ||============================== //
const Espace_recruteur = () => {
    let history = useNavigate();
    const { name, email, password, role } = AuthUser();
    //console.log(senddata);
    const [candidats, setCandidats] = useState([]);
    const [origin, setOrigin] = useState([]);
    useEffect(() => {
        if(email!='' && role!='')
{        load();
}
else{
    history('/login');
}
    }, []);
    const load = () => {
        async function f()
        {
            try
            {
                const requrl = 'https://sourcing.openmind-its.com/api/listfichEntretien';
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                setCandidats(json);
            } 
            catch (error) {
                //console.error('error');
            }
        }
        f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addCandidat');
    };
    ///
    ///modifier
    const modifierfiche =(profil,offre,id) =>{
        history('/vue_recruteur_ModifierficheDentretien/' + profil + '/' + offre + '/' + id);
    
       }
     ///format date

     const date_modifiee = (dateR) => {
        //const dateString = '2022-09-15 T19:52:01.000000Z';
        const date = new Date(dateR);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      
        return formattedDate; // Output: 09/15/2022
      };
///supprimer
    const changerStatus = async (id) => {
        axios
            .get('https://sourcing.openmind-its.com/api/updateStatusCandidat/' + id)
            .then(function (response) {
                load();
            })
            .catch(function (error) {});
    };
    const reload = () => {
        load();
    };



//Search
    const handleSearch = (event) => {
        if (event.target.value == 'active') {
            setCandidats(origin.filter((cand) => cand.status.includes('active')));
        } else if (event.target.value == 'archivé') {
            setCandidats(origin.filter((cand) => cand.status.includes('archivé')));
        } else if (event.target.value == 'invalid') {
            setCandidats(origin.filter((cand) => cand.status.includes('invalid')));
        } else {
            load();
            setCandidats(candidats);
        }

        // setSearch(event.target.value);
    };
    return (
        <MainCard title="Liste Fiche Entretiens">
            <Box mt={1} sx={{ float: 'right', width: '14rem' }}>
                <FormControl fullWidth>
                    <InputLabel id="status">Filtrer par Status </InputLabel>
                    <Select labelId="status" label="Filtrer par Status" id="status" name="status" onChange={handleSearch}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="archivé">Archivé</MenuItem>
                        <MenuItem value="invalid">Invalide</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Recruteur</TableCell>
                                                    <TableCell align="center">Titre Offre</TableCell>
                                                    <TableCell align="center">Nom Complet</TableCell>
                                                    <TableCell align="center">Titre du profil</TableCell>
                                                    <TableCell align="center">Audio(Oui,Non)</TableCell>
                                                    <TableCell align="center">Date Création</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {candidats.length != 0 ? (
                                                    candidats.map((row) => (
                                                        <TableRow
                                                            key={row.profil_id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="center">{row.recruteur}</TableCell>
                                                            <TableCell align="center">{row.titre_Offre}</TableCell>
                                                            <TableCell align="center">{row.nom}{' '}{row.prenom}</TableCell>
                                                            <TableCell align="center">{row.titreduprofil}</TableCell>
                                                            <TableCell align="center"> Oui </TableCell>
                                                            {/* {row.fentre_id !=''? 'Oui':'Non'} */}
                                                            <TableCell align="center">{date_modifiee(row.created_at)}</TableCell>
                                                            <TableCell align="center">
                                                                {' '}
                                                                <Link
                                                                    to={'/vue_recruteur_detailFicheEntretien/' + row.id}
                                                                    fullWidth
                                                                    size="large"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                >
                                                               Détails
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {' '}
                                                                <AnimateButton>
                                                                    <Button
                                                                        fullWidth
                                                                        onClick={() =>modifierfiche(row.profil_id,row.offre_id,row.id)}
                                                                        size="large"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        Modifier
                                                                    </Button>   
                                                                </AnimateButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow key="1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <Box mt={2} sx={{ textAlign: 'right' }}>
                                                            {'Aucun Candidat avec ce Status '}
                                                        </Box>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
};
export default Espace_recruteur;
