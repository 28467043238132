import { useState } from 'react';
import { Box, Grid, TextField, MenuItem, Input, Select, Button, Alert, FormControl, InputLabel } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthUser from 'AuthUser';

import Moment from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const { name, email, password, role } = AuthUser();
const Recruteur_addCandidat = () => {
    let history = useNavigate(); //use for navigate previons
    const { id } = useParams();
    const [data, setData] = useState({
        candidats_id: '',
        titreduprofil: '',
        typeduprofil: '',
        pretentionssalariales: '',
        status: '',
        show: 'false',
        entreprise: '',
        secteur: '',
        datefin: '',
        datedebut: '',
        mission: '',
        cadretechnique: '',
        methodologies: ''
    });

    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const formatDatedebut = Moment(data.datedebut);
    const formatDatefin = Moment(data.datefin);

    const submitForm = (e) => {
if( email !='' || role!='')
    {
        const formatDate = Moment(data.dateNaissance);

        const fData = new FormData();
        fData.append('cv', cvdata);
        console.log(fData);
        console.log(data);
        axios
            .post('https://sourcing.openmind-its.com/api/Profils', {
                candidats_id: id,
                titreduprofil: data.titreduprofil,
                typeduprofil: data.typeduprofil,
                cv: data.cv,
                pretentionssalariales: data.pretentionssalariales,
                status: data.status,
                entreprise: data.entreprise,
                secteur: data.secteur,
                datefin: formatDatefin.format('YYYY-MM-DD'),
                datedebut: formatDatedebut.format('YYYY-MM-DD'),
                mission: data.mission,
                cadretechnique: data.cadretechnique,
                methodologies: data.methodologies
            })
            .then(function (response) {
                setData((data.show = false));
                //upload cv
                console.log(response.data);
                axios
                    .post('https://sourcing.openmind-its.com/api/Candidats/uploadcvs/' + response.data, fData)
                    .then((res) => {
                        // console.log('response', res);
                        alert(res.data.message);
                        history('/vue_recruteur_detailCandidat/' + id);
                    })
                    .catch((e) => {});
                setData((data.show = false));

                //end upload
            })
            .catch(function (error) {
                setData((data.show = true));
            });
    }
    else alert('une connexion est requise');
        //}
    };
    //upload cv
    const [cvdata, setcvData] = useState('');
    const handleChanges = (file) => {
        setcvData(file[0]);
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date
    const [startDate, setStartDate] = useState(new Date());
    const [startDatedebut, setStartDatedebut] = useState(new Date());
    const [startDatefin, setStartDatefin] = useState(new Date());
    return (
        <>
            {data.show ? (
                <div></div>
            ) : (
                <Alert variant="filled" severity="error">
                    Merci d'inserer tout les champs !!
                </Alert>
            )}
            <MainCard title={'Ajouter Profile pour : ' + id}>
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="titre du profil"
                                                name="titreduprofil"
                                                id="titreduprofil"
                                                type="text"
                                                value={data.titreduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="type du profil"
                                                name="typeduprofil"
                                                id="typeduprofil"
                                                type="text"
                                                value={data.typeduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <input name="cv" id="cv" type="file" onChange={(e) => handleChanges(e.target.files)} />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="pretentions salariales"
                                                name="pretentionssalariales"
                                                id="pretentionssalariales"
                                                type="text"
                                                value={data.pretentionssalariales}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="entreprise"
                                                name="entreprise"
                                                id="entreprise"
                                                type="text"
                                                value={data.entreprise}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="secteur"
                                                name="secteur"
                                                id="secteur"
                                                type="text"
                                                value={data.secteur}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <InputLabel for="startDatedebut">start Date debut:</InputLabel>
                                            <Input
                                                fullWidth
                                                onChange={handlChanged}
                                                id="startDatedebut"
                                                name="datedebut"
                                                type="Date"
                                                selected={startDatedebut}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <InputLabel for="datefin">date fin:</InputLabel>
                                            <Input
                                                fullWidth
                                                onChange={handlChanged}
                                                id="datefin"
                                                name="datefin"
                                                type="Date"
                                                selected={startDatefin}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="mission"
                                                name="mission"
                                                id="mission"
                                                type="text"
                                                value={data.mission}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="cadretechnique"
                                                name="cadretechnique"
                                                id="cadretechnique"
                                                type="text"
                                                value={data.cadretechnique}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="methodologies"
                                                name="methodologies"
                                                id="methodologies"
                                                type="text"
                                                value={data.methodologies}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="status">Status </InputLabel>
                                                <Select labelId="status" label="Status" id="status" name="status" onChange={handlChanged}>
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="inactive">Inactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Ajouter
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
