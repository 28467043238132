import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';

import { useHistory, useParams,useLocation } from 'react-router-dom';

import { Navigate, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// ==============================|| SAMPLE PAGE ||============================== //

//

const Recruteur_offre = () => {
    let history = useNavigate(); //use for navigate previons
    const { id } = useParams();
    const [data, setData] = useState({
        titre_Offre: '',
        Status: '',
        Descriptif_Poste: '',
        Profil_Recherché: '',
        Remuneration: '',
        AvantageContext: '',
        Localisation: '',
        Points_Incontournables: ''
    });
    const [tage, setTage] = useState([]);
    const handleDelete = (chipToDelete) => () => {
        //setTage((tags) => tags.filter((tag) => tag.id !== chipToDelete.id));
        setTage(tage.filter((tag) => tag.id !== chipToDelete.id));
    };
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const [profile, setProfile] = useState([]);
    useEffect(() => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Offres/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json['offre']);
                console.log(json['tags']);
                setData(json['offre']);
                //setTage(queryParams.tags);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }, []);
    const modifierOffre = (e) => {
        //const formatDate = Moment(data.dateNaissance);
        // alert(formatDate.format('YYYY-MM-DD'));
        if (
            data.titre_Offre == '' ||
            data.Status == '' ||
            data.Descriptif_Poste == '' ||
            data.Profil_Recherché == '' ||
            data.Remuneration == '' ||
            data.AvantageContext == '' ||
            data.Localisation == '' ||
            data.Points_Incontournables == ''
        ) {
            //alert
            //setData((data.show = true));
            //ajouter candidasett
        } else {
            console.log(data);

            axios
                .put('https://sourcing.openmind-its.com/api/Offres/' + id, {
                    titre_Offre: data.titre_Offre,
                    Status: data.Status,
                    Descriptif_Poste: data.Descriptif_Poste,
                    Profil_Recherché: data.Profil_Recherché,
                    Remuneration: data.Remuneration,
                    AvantageContext: data.AvantageContext,
                    Localisation: data.Localisation,
                    Points_Incontournables: data.Points_Incontournables
                })
                .then(function (response) {
                    setData((data.show = false));
                    alert('Votre Offre est Bien Modifier ;) ');
                    history('/vue_entreprise_tagOffre/' + data.id);
                })
                .catch(function (error) {
                    //   setData((data.show = true));
                    alert(error);
                });
        }
    };
    const modofierTages = () => {
        axios
            .put('https://sourcing.openmind-its.com/api/Tage_Offres/' + id, {
                tages: tage
            })
            .then(function (response) {
                alert('Bien Valider');
                history('/vue_entreprise_listOffres');
            })
            .catch(function (error) {
                alert(error);
            });
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <MainCard title="Modifier Offre">
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        password: Yup.string().max(255).required('Password is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            if (scriptedRef.current) {
                                setStatus({ success: true });
                                setSubmitting(false);
                            }
                        } catch (err) {
                            console.error(err);
                            if (scriptedRef.current) {
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                setSubmitting(false);
                            }
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs zeroMinWidth>
                                    <TextField
                                        multiline
                                        fullWidth
                                        label="Titre Offre"
                                        margin="normal"
                                        name="titre_Offre"
                                        type="text"
                                        value={data.titre_Offre}
                                        onChange={handlChanged}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Etat">Status de L'offre</InputLabel>
                                                <Select labelId="Etat" label="Status" id="Status" name="Status" onChange={handlChanged}>
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="inactive">Inactive</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Remuneration"
                                                name="Remuneration"
                                                type="text"
                                                value={data.Remuneration}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Profil Recherche"
                                                margin="normal"
                                                name="Profil_Recherché"
                                                type="text"
                                                value={data.Profil_Recherché}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Descriptif Poste"
                                                name="Descriptif_Poste"
                                                type="text"
                                                value={data.Descriptif_Poste}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="AvantageContext"
                                                margin="normal"
                                                name="AvantageContext"
                                                type="text"
                                                value={data.AvantageContext}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Localisation"
                                                margin="normal"
                                                name="Localisation"
                                                type="text"
                                                value={data.Localisation}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                multiline
                                                fullWidth
                                                label="Points_Incontournables"
                                                margin="normal"
                                                name="Points_Incontournables"
                                                type="text"
                                                value={data.Points_Incontournables}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item tem xs={2}>
                                            <AnimateButton>
                                                <Button
                                                    disableElevation
                                                    disabled={isSubmitting}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={modifierOffre}
                                                >
                                                    Modifier L'offre
                                                </Button>
                                            </AnimateButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_offre;
