import { useState, useEffect } from 'react';
import { Box, Grid, TextField, MenuItem, Input, Select, Button, Alert, FormControl, InputLabel } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useHistory, useParams } from 'react-router-dom';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

import Moment from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_detailCandidat = () => {
    let history = useNavigate(); //use for navigate previons
    const { id } = useParams();
    const [data, setData] = useState({
        id: '',
        nom: '',
        prenom: '',
        datenaissance: new Date(),
        ville: '',
        pays: '',
        email: '',
        etatduprofil: '',

        mobilite: '',
        status: '',
        show: 'false'
    });
    //const { nom, prenom, dateNaissance, ville, pays, email, cv, titre, etat, mobilite, active, pretentionSalarial } = $user;

    useEffect(() => {
        if(email!='' &&( role==='Recruteur' || role ==='Admin'))
        {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Candidats/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json.candidatData['nom']);
                setData(json.candidatData);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }

else{
    history('/login');
}
    }, []);
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    //ModifierCandidat :
    const submitForm = (e) => {
        const formatDate = Moment(data.datenaissance);
        // alert(formatDate.format('YYYY-MM-DD'));
        /*  if (
            data.nom == '' ||
            data.prenom == '' ||
            data.dateNaissance == '' ||
            data.ville == '' ||
            data.pays == '' ||
            data.email == '' ||
            data.titre == '' ||
            data.etat == '' ||
            data.mobilite == '' ||
            data.active == '' ||
            data.pretentionSalarial == ''
        ) {
            //alert
            setData((data.show = true));
            //up candidasett
        } else {*/
        console.log(data);
        axios
            .put('https://sourcing.openmind-its.com/api/Candidats/' + id, {
                id: id,
                nom: data.nom,
                prenom: data.prenom,
                datenaissance: formatDate.format('YYYY-MM-DD'),
                ville: data.ville,
                pays: data.pays,
                email: data.email,
                etatduprofil: data.etatduprofil,

                etatduprofil: data.etatduprofil,
                mobilite: data.mobilite,
                status: data.status
            })
            .then(function (response) {
                setData((data.show = false));
                history('/vue_recruteur_listCandidats');
            })
            .catch(function (error) {
                //setData((data.show = true));
                alert(error);
            });
        //}
    };

    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <MainCard title="Detail Candidat">
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Nom"
                                                name="nom"
                                                id="nom"
                                                type="text"
                                                value={data.nom}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Prenom"
                                                name="prenom"
                                                id="prenom"
                                                type="text"
                                                value={data.prenom}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <Input
                                                fullWidth
                                                onChange={handlChanged}
                                                name="datenaissance"
                                                type="Date"
                                                selected={startDate}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Ville"
                                                id="ville"
                                                name="ville"
                                                type="text"
                                                value={data.ville}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Pays"
                                                name="pays"
                                                id="pays"
                                                type="text"
                                                value={data.pays}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                id="email"
                                                type="email"
                                                value={data.email}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Etat Du Profil"
                                                name="etatduprofil"
                                                id="etatduprofil"
                                                type="text"
                                                value={data.etatduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Mobilité"
                                                name="mobilite"
                                                id="mobilite"
                                                type="text"
                                                value={data.mobilite}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="Etat">Status</InputLabel>
                                                <Select labelId="Etat" label="Status" id="etat" name="status" onChange={handlChanged}>
                                                    <MenuItem value="active">Active</MenuItem>
                                                    <MenuItem value="archive">Archive</MenuItem>
                                                    <MenuItem value="invalid">Invalid</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Modifier
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_detailCandidat;
