import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
//session
import { ReactSession } from 'react-client-session';

import { Navigate } from 'react-router-dom';

export default function AuthUser() {
    const name = window.localStorage.getItem('name');
    const email = window.localStorage.getItem('email');
    const password = window.localStorage.getItem('password');
    const role = window.localStorage.getItem('role');
    const id = window.localStorage.getItem('id');
    return {
        name,
        email,
        password,
        role,
        id
    };
}
