import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem,
    label
} from '@mui/material';
//table

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import AuthUser from 'AuthUser';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table
import * as React from 'react';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import { FaceRetouchingOffSharp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// ==============================|| SAMPLE PAGE ||============================== //
const Entreprise_listOffres = () => {
    let history = useNavigate();
    //console.log(senddata);
    //

    //


    const{id} = AuthUser(); 
    const [offres, setOffres] = useState([]);
    useEffect(() => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/entreprise/listOffres/'+id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                setOffres(json);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }, []);
    const redirection = (e) => {
        history('/vue_entreprise_offre/'+id);
    };

    //supprimer
    const archive = async (id) => {
        //  alert('suprimer : ' + id);
        axios
            .get('https://sourcing.openmind-its.com/api/updateStatusOffre/' + id)
            .then(function (response) {
                //history('/vue_entreprise_listOffres');
                //window.location.reload(false);
                setOffres(offres.filter((off) => off.id !== id));
            })
            .catch(function (error) {
                // setData((data.show = true));
            });
    };

    return (
        <MainCard title="Liste Offres">
            <Button onClick={redirection} variant="contained" color="primary">
                Ajouter Offre
            </Button>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Id</TableCell>
                                                    <TableCell align="right">Titre Offre</TableCell>
                                                    <TableCell align="right">Remuneration</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {offres.map((row) => (
                                                    <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell align="right">{row.titre_Offre}</TableCell>
                                                        <TableCell align="right">{row.Remuneration}</TableCell>
                                                        <TableCell align="right">{row.Status}</TableCell>
                                                        <TableCell align="right">
                                                            {' '}
                                                            <Link
                                                                to={'/vue_entreprise_tagOffre/'+ row.id}
                                                                fullWidth
                                                                size="large"
                                                                type="button"
                                                                variant="contained"
                                                                color="secondary"
                                                            >
                                                              Détails
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {' '}
                                                            <AnimateButton>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => archive(row.id)}
                                                                    size="large"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                    Archivé
                                                                </Button>
                                                            </AnimateButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
};
export default Entreprise_listOffres;
