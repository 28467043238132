// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
//session
import { ReactSession } from 'react-client-session';
import AuthUser from 'AuthUser';
// constant
const icons = { IconBrandChrome, IconHelp };
//const name = ReactSession.get('name');
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
let other = '';
const { name, email,role,id} = AuthUser();
if (name != '') {
    if (name != null) {
        if (role == 'Recruteur') {
            other = {
                id: 'other',
                title: 'Spaces',
                caption: 'Pages Caption',
                type: 'group',
                children: [
                  // {
                    //     id: 'Entreprise',
                    //     title: 'ACCES Entreprise',
                    //     type: 'collapse',
                    //     icon: icons.IconKey,
    
                    //     children: [
                    //         {
                    //             id: 'entreprise_forfait',
                    //             title: 'Profil ',
                    //             type: 'item',
                    //             url: '/vue_entreprise_profil/'+id,
                    //             icon: icons.IconBrandChrome,
                    //             breadcrumbs: false
                    //         },
                    //         {
                    //             id: 'entreprise_listOffres',
                    //             title: 'Mes Offres',
                    //             type: 'item',
                    //             url: '/vue_entreprise_listOffres/'+id,
                    //             icon: icons.IconBrandChrome,
                    //             breadcrumbs: false
                    //         },
                    //         {
                    //             id: 'entreprise_profileCondidat',
                    //             title: 'Profil Candidats',
                    //             type: 'item',
                    //             url: '/vue_entreprise_profileCandidat'+id,
                    //             icon: icons.IconBrandChrome,
                    //             breadcrumbs: false
                    //         }
                    //     ]
                    // },
                    // {
                    //     id: 'Candidat',
                    //     title: 'ACCES Candidat',
                    //     type: 'collapse',
                    //     icon: icons.IconKey,
    
                    //     children: [
                    //         {
                    //             id: 'candidat_profile',
                    //             title: 'Profile',
                    //             type: 'item',
                    //             url: '/espace_candidat',
                    //             icon: icons.IconBrandChrome,
                    //             breadcrumbs: false
                    //         },
                    //         {
                    //             id: 'candidat_offers',
                    //             title: 'Offers',
                    //             type: 'item',
                    //             url: '/espace_candidat',
                    //             icon: icons.IconBrandChrome,
                    //             breadcrumbs: false
                    //         }
                    //     ]
                    // },
                    {
                        id: 'Recruteur',
                        title: 'ACCES Recruteur',
                        type: 'collapse',
                        icon: icons.IconKey,
                        children: [
                            // {
                            //     id: 'vue_recruteur_ListUsers',
                            //     title: 'Liste Users',
                            //     type: 'item',
                            //     url: '/vue_recruteur_ListUsers',
                            //     icon: icons.IconBrandChrome,
                            //     breadcrumbs: false
                            // },
                            {
                                id: 'recruteur_profileCandidat',
                                title: 'Liste Candidats',
                                type: 'item',
                                url: '/vue_recruteur_listCandidats',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'recruteur_profileListEntreprises',
                                title: 'Liste Entreprises',
                                type: 'item',
                                url: '/vue_recruteur_listEntreprise',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'recruteur_listOffres',
                                title: 'Liste Offres',
                                type: 'item',
                                url: '/vue_recruteur_listOffres',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            // {
                            //     id: 'Ajouter Candidat',
                            //     title: 'Ajouter Candidat',
                            //     type: 'item',
                            //     url: '/vue_recruteur_addCandidat',
                            //     icon: icons.IconBrandChrome,
                            //     breadcrumbs: false
                            // },
                            {
                                id: 'dictionnaire_tages',
                                title: 'Dictionnaire tags',
                                type: 'item',
                                url: '/vue_recruteur_dictionnaire',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                       
                            {
                                id: 'recruteur_listFicheEntretien',
                                title: 'Liste Fiches Entretien',
                                type: 'item',
                                url: '/vue_recruteur_listFicheEntretien',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            // {
                            //     id: 'recruteur_forfait',
                            //     title: 'Forfait',
                            //     type: 'item',
                            //     url: '/espace_recruteur',
                            //     icon: icons.IconBrandChrome,
                            //     breadcrumbs: false
                            // }
                        ]
                    },
                    ,
                    // {
                    //     id: 'Valideur',
                    //     title: 'ACCES Valideur',
                    //     type: 'collapse',
                    //     icon: icons.IconKey,
                    //     children: [
                    //         {
                    //             id: 'valideur_index',
                    //             title: 'validation',
                    //             type: 'item',
                    //             url: '/espace_recruteur',
                    //             icon: icons.IconBrandChrome,
                    //             breadcrumbs: false
                    //         }
                    //     ]
                    // }
                ]
            };
        }
        ///ACCES admin
        if (role == 'Admin') {
            other = {
                id: 'other',
                title: 'Spaces',
                caption: 'Pages Caption',
                type: 'group',
                children: [
                  {
                        id: 'Entreprise',
                        title: 'ACCES Entreprise',
                        type: 'collapse',
                        icon: icons.IconKey,
    
                        children: [
                            {
                                id: 'entreprise_forfait',
                                title: 'Profil ',
                                type: 'item',
                                url: '/vue_entreprise_profil/'+id,
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'entreprise_listOffres',
                                title: 'Mes Offres',
                                type: 'item',
                                url: '/vue_entreprise_listOffres/'+id,
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'entreprise_profileCondidat',
                                title: 'Profil Candidats',
                                type: 'item',
                                url: '/vue_entreprise_profileCandidat/'+id,
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            }
                        ]
                    },
                    {
                        id: 'Candidat',
                        title: 'ACCES Candidat',
                        type: 'collapse',
                        icon: icons.IconKey,
    
                        children: [
                            {
                                id: 'candidat_profile',
                                title: 'Profile',
                                type: 'item',
                                url: '/espace_candidat',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'candidat_offers',
                                title: 'Offers',
                                type: 'item',
                                url: '/espace_candidat',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            }
                        ]
                    },
                    {
                        id: 'Recruteur',
                        title: 'ACCES Recruteur',
                        type: 'collapse',
                        icon: icons.IconKey,
                        children: [
                            {
                                id: 'vue_recruteur_ListUsers',
                                title: 'Liste Users',
                                type: 'item',
                                url: '/vue_recruteur_ListUsers',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'recruteur_profileCandidat',
                                title: 'Liste Candidats',
                                type: 'item',
                                url: '/vue_recruteur_listCandidats',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'recruteur_profileListEntreprises',
                                title: 'Liste Entreprises',
                                type: 'item',
                                url: '/vue_recruteur_listEntreprise',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'recruteur_listOffres',
                                title: 'Liste Offres',
                                type: 'item',
                                url: '/vue_recruteur_listOffres',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            // {
                            //     id: 'Ajouter Candidat',
                            //     title: 'Ajouter Candidat',
                            //     type: 'item',
                            //     url: '/vue_recruteur_addCandidat',
                            //     icon: icons.IconBrandChrome,
                            //     breadcrumbs: false
                            // },
                            {
                                id: 'dictionnaire_tages',
                                title: 'Dictionnaire tags',
                                type: 'item',
                                url: '/vue_recruteur_dictionnaire',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                       
                            {
                                id: 'recruteur_listFicheEntretien',
                                title: 'Liste Fiches Entretien',
                                type: 'item',
                                url: '/vue_recruteur_listFicheEntretien',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            // {
                            //     id: 'recruteur_forfait',
                            //     title: 'Forfait',
                            //     type: 'item',
                            //     url: '/espace_recruteur',
                            //     icon: icons.IconBrandChrome,
                            //     breadcrumbs: false
                            // }
                        ]
                    },
                    ,
                    {
                        id: 'Valideur',
                        title: 'ACCES Valideur',
                        type: 'collapse',
                        icon: icons.IconKey,
                        children: [
                            {
                                id: 'valideur_index',
                                title: 'validation',
                                type: 'item',
                                url: '/espace_recruteur',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            };
        }

        //Accee Entrerise
        if (role == 'Entreprise') {
            other = {
                id: 'other',
                title: 'Spaces',
                caption: 'Pages Caption',
                type: 'group',
                children: [
                    {
                        id: 'Entreprise',
                        title: 'ACCES Entreprise',
                        type: 'collapse',
                        icon: icons.IconKey,
    
                        children: [
                            {
                                id: 'entreprise_forfait',
                                title: 'Profil ',
                                type: 'item',
                                url: '/vue_entreprise_profil/'+id,
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'entreprise_listOffres',
                                title: 'Mes Offres',
                                type: 'item',
                                url: '/vue_entreprise_listOffres/'+id,
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            },
                            {
                                id: 'entreprise_profileCondidat',
                                title: 'Profil Candidats',
                                type: 'item',
                                url: '/vue_entreprise_profileCandidat/'+id,
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            };
        }
        //Accee Valideur
        if (role == 'Valideur') {
            other = {
                id: 'other',
                title: 'Spaces',
                caption: 'Pages Caption',
                type: 'group',
                children: [
                    {
                        id: 'Valideur',
                        title: 'ACCES Valideur',
                        type: 'collapse',
                        icon: icons.IconKey,
                        children: [
                            {
                                id: 'valideur_index',
                                title: 'validation',
                                type: 'item',
                                url: '/espace_recruteur',
                                icon: icons.IconBrandChrome,
                                breadcrumbs: false
                            }
                        ]
                    }
                ]
            };
        }
         //Accee Valideur
        //  if (role == 'Admin') {
        //     other = {
        //         id: 'other',
        //         title: 'Spaces',
        //         caption: 'Pages Caption',
        //         type: 'group',
        //         children: [
        //             {
        //                 id: 'Candidat',
        //                 title: 'ACCES Candidat',
        //                 type: 'collapse',
        //                 icon: icons.IconKey,
    
        //                 children: [
        //                     {
        //                         id: 'candidat_profile',
        //                         title: 'Profile',
        //                         type: 'item',
        //                         url: '/espace_candidat',
        //                         icon: icons.IconBrandChrome,
        //                         breadcrumbs: false
        //                     },
        //                     {
        //                         id: 'candidat_offers',
        //                         title: 'Offers',
        //                         type: 'item',
        //                         url: '/espace_candidat',
        //                         icon: icons.IconBrandChrome,
        //                         breadcrumbs: false
        //                     }
        //                 ]
        //             },
        //         ]
        //     };
        // }
       
    }
} else {
    other = {
        id: 'other',
        title: '',
        caption: 'Pages Caption',
        type: 'group',
        children: []
    };
}

export default other;
