import { useState } from 'react';
import { Box, Grid, TextField, MenuItem, Input, Select, Button, Alert, FormControl, InputLabel } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import { DatePicker, Space } from 'antd';
//pour les réquete http (post get put selete)

import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import AuthUser from 'AuthUser';
import Moment from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_addCandidat = () => {
    let history = useNavigate(); //use for navigate previons
    const { id } = useParams();
    const { email,role} = AuthUser(); 
    const [data, setData] = useState({
        candidats_id: '',
        titreduprofil: '',
        typeduprofil: '',
        pretentionssalariales: '',
        status: '',
        entreprise: '',
        secteur: '',
        mission: '',
        cadretechnique: '',
        methodologies: ''
    });
    const [dossierCompetence, setDossierCompetence] = useState([]);
    useEffect(() => {
        if(email!='' &&( role==='Recruteur' || role ==='Admin'))
        {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Profils/showProfile/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                setData(json[0]);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }
    else{
        history('/login');
    }
    }, []);
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    //upload cv
    const [cvdata, setcvData] = useState('');
    const handleChanges = (file) => {
        setcvData(file[0]);
    };

    const submitForm = (e) => {
        const formatDate = Moment(data.dateNaissance);
        // alert(formatDate.format('YYYY-MM-DD'));
        /* if (true) {
            //alert
            setData((data.show = true));
            //ajouter candidasett
        } else {*/
        // alert(id);
        const fData = new FormData();
        fData.append('cv', cvdata);
        //console.log(fData);
        //console.log(data);
        // console.log(data);
        axios
            .put('https://sourcing.openmind-its.com/api/Profils/' + id, {
                titreduprofil: data.titreduprofil,
                typeduprofil: data.typeduprofil,
                pretentionssalariales: data.pretentionssalariales,
                status: data.status
            })
            .then(function (response) {
                //  console.log(response.data);
                axios
                    .post('https://sourcing.openmind-its.com/api/Candidats/uploadcvs/' + id, fData)
                    .then((res) => {
                        console.log('response', res);
                    })
                    .catch((e) => {
                        console.log('error', e);
                    });
                setData((data.show = false));
                // alert(response.message);
                //end upload
                //history('/vue_recruteur_detailCandidat/' + id);*/
                history('/vue_recruteur_detailCandidat/' + response.data);
                //alert('hi');
            })
            .catch(function (error) {});
        //}
    };
    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date

    return (
        <>
            <MainCard title={'Modifier User'}>
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="titre du profil"
                                                name="titreduprofil"
                                                id="titreduprofil"
                                                type="text"
                                                value={data.titreduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="type du profil"
                                                name="typeduprofil"
                                                id="typeduprofil"
                                                type="text"
                                                value={data.typeduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="pretentions salariales"
                                                name="pretentionssalariales"
                                                id="pretentionssalariales"
                                                type="text"
                                                value={data.pretentionssalariales}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="status">Status </InputLabel>
                                                <Select labelId="status" label="Status" id="status" name="status" onChange={handlChanged}>
                                                    <MenuItem value="active">Actif</MenuItem>
                                                    <MenuItem value="inactive">Inactif</MenuItem>
                                                    <MenuItem value="active">A l'écout du marchée</MenuItem>
                                                    <MenuItem value="inactive">En cours du positionnement </MenuItem>
                                                    <MenuItem value="active">En attente du retour du client</MenuItem>
                                                    <MenuItem value="inactive">Positionné</MenuItem>
                                            
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <InputLabel id="status">CV : </InputLabel>

                                            <input name="cv" id="cv" type="file" onChange={(e) => handleChanges(e.target.files)} />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Modifier
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_addCandidat;
