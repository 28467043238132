// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthUser from 'AuthUser';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //
const { name, email } = AuthUser();
let utilities = {};

if (name != '') {
    if (name != null) {
        utilities = {
            id: 'utilities',
            title: 'Connexion',
            type: 'group',
            children: [
                {
                    id: 'util-color',
                    title: 'Logout',
                    type: 'item',
                    url: '/logout',
                    icon: LogoutIcon,
                    breadcrumbs: false
                },
                {
                    id: 'util-profile',
                    title: 'Profile',
                    type: 'item',
                    url: '/profile',
                    icon: AccountCircleIcon,
                    breadcrumbs: false
                }
            ]
        };
    }
} else {
    utilities = {
        id: 'utilities',
        title: 'Connexion',
        type: 'group',
        children: [
            {
                id: 'util-typography',
                title: 'Login',
                type: 'item',
                url: '/utils/util-typography',
                icon: LoginIcon,
                breadcrumbs: false
            }
        ]
    };
}

export default utilities;
