import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem,
    label
} from '@mui/material';
//table
import DownloadLink from 'react-download-link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// project imports
import { useHistory, useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table
import * as React from 'react';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import { FaceRetouchingOffSharp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AuthUser from 'AuthUser';

// ==============================|| SAMPLE PAGE ||============================== //
const Espace_recruteur = () => {
    const { id } = useParams();
    let history = useNavigate();
    const { name, email, password, role } = AuthUser();
    //console.log(senddata);
    const [profile, setProfile] = useState([]);
    const [nombre, setnombre] = useState([]);

    const [offre, setOffre] = useState({
        id: '',
        titre_Offre: '',
        Points_Incontournables: '',
        Descriptif_Poste: '',
        Profil_Recherché: '',
        Remuneration: '',
        Localisation: '',
        AvantageContext: '',
        Status: ''
    });
    useEffect(() => {
        if(email!='' && role!='')
        {
        load();
   }
else history('/login');
   }, []);
    

    //load data
    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/liste_ordonne/' + id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json[0][0]);
                setProfile(json[0][0]);
                setOffre(json[1]);
            } catch (error) {
                console.error('error');
            }
        }
       f();
    };
    const redirection = (e) => {
        history('/vue_recruteur_addCandidat');
    };

    //changerStatus
    const changerStatus = async (id) => {
        axios
            .get('https://sourcing.openmind-its.com/api/listProfilesOffre/' + id)
            .then(function (response) {
                //history('/vue_entreprise_listOffres');
                //window.location.reload(false);
                load();
                // setStudantList(studentList.filter((off) => off.id !== id));
            })
            .catch(function (error) {
                // setData((data.show = true));
            });
    };
    //fiche d'entretien
    const ficheDentretien = async (id) => {
        history('/vue_recruteur_ficheDentretien/' + id + '/' + offre.id);
    };
   ///details de la fiche d'entretiens
    const detailfiche = async (id)=>{
      axios
      .post('https://sourcing.openmind-its.com/api/fichecandidat/'+ id +'/' +offre.id)
      .then(function (response) {
        const resId=response.data.id;
        // setStudantList(studentList.filter((off) => off.id !== id));
        if(resId !='')
        {
        history('/vue_recruteur_detailFicheEntretien/'+ resId);
        }
        else {
            alert('Aucune fiche d\'entretien qui correspond à ce profil');
        }
    })
       .catch(function (error) {
        // setData((data.show = true));
    });
    };
///affichage au cas d'un recruteur /admin/public
    if(role!='Entreprise')
    {
    return (
        <MainCard title={'Liste Candidats de Loffre : ' + offre.titre_Offre}>
            <Button onClick={redirection} variant="contained" color="primary">
                Ajouter Candidat
            </Button>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Nom Complet </TableCell>
                                                    <TableCell align="center">Titre du profil</TableCell>
                                                    <TableCell align="center">état du profil</TableCell>
                                                    <TableCell align="center">Date Création</TableCell>
                                                    <TableCell align="center">Titre offre</TableCell>
                                                    <TableCell align="center">% Corréspondance</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {profile.map((row) => (
                                                    <TableRow
                                                        key={row.candidats_id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center">
                                                            {row.nom}
                                                            {row.prenom}
                                                        </TableCell>
                                                        <TableCell align="center">{row.titreduprofil}</TableCell>

                                                        <TableCell align="center">{row.etatduprofil}</TableCell>
                                                        <TableCell align="center">{row.created_at}</TableCell>
                                                        <TableCell align="center">{row.titre_Offre}</TableCell>
                                                        <TableCell align="center">{row.n}%</TableCell>
                                                        <TableCell align="center">
                                                            {' '}
                                                            <Link
                                                                to={'/vue_recruteur_detailCandidat/' + row.id}
                                                                fullWidth
                                                                size="large"
                                                                type="button"
                                                                variant="contained"
                                                                color="secondary"
                                                            >
                                                                Détails
                                                            </Link>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {' '}
                                                            <AnimateButton>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => changerStatus(row.id)}
                                                                    size="small"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                   Nouvelle Offre
                                                                </Button>
                                                            </AnimateButton>
                                                            
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {' '}
                                                            <AnimateButton>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => ficheDentretien(row.id)}
                                                                    size="small"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                   ajouter fiche
                                                                </Button>
                                                            </AnimateButton>
                                                            </TableCell>
                                                        <TableCell>
                                                            <AnimateButton>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => detailfiche(row.id)}
                                                                    size="small"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                   Détails fiche
                                                                </Button>
                                                            </AnimateButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
}

///affichage au cas d'une entreprise
if(role==='Entreprise'){
    return (
        <MainCard title={'Liste Candidats de L\'offre : ' + offre.titre_Offre}>
          
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">id Profil </TableCell>
                                                    <TableCell align="center">Titre du profil</TableCell>
                                                    <TableCell align="center">état du profil</TableCell>
                                                    <TableCell align="center">Date Création</TableCell>
                                                    <TableCell align="center">% Corréspondance</TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {profile.length != 0 ? (
                                                profile.map((row) => (
                                                    <TableRow
                                                        key={row.candidats_id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center">
                                                            {row.id}                                                     
                                                        </TableCell>
                                                        <TableCell align="center">{row.titreduprofil}</TableCell>

                                                        <TableCell align="center">{row.etatduprofil}</TableCell>
                                                        <TableCell align="center">{row.created_at}</TableCell>
                                                        <TableCell align="center">{row.n}%</TableCell>
                                                        <TableCell align="center">
                                                            {' '}
                                                            <Link
                                                                to={'/vue_recruteur_detailCandidat/' + row.candidats_id}
                                                                fullWidth
                                                                size="large"
                                                                type="button"
                                                                variant="contained"
                                                                color="secondary"
                                                            >
                                                                Détails Candidat
                                                            </Link>
                                                        </TableCell>
                                                      
                                                     
                                                        <TableCell>
                                                            <AnimateButton>
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => detailfiche(row.id)}
                                                                    size="small"
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="primary"
                                                                >
                                                                   Détails fiche d'entretien
                                                                </Button>
                                                            </AnimateButton>
                                                        </TableCell>
                                                    </TableRow>
                                                         ))
                                                         ) : (
                                                             <TableRow key="1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                 <Box mt={2} sx={{ textAlign: 'right' }}>
                                                                     {'Aucun candidat qui correspond à cette offre '}
                                                                 </Box>
                                                             </TableRow>
                                                         )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );

}
    
};
export default Espace_recruteur;
