import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem,
    label
} from '@mui/material';
//dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
//table

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table

import AuthUser from 'AuthUser';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { func } from 'prop-types';
import { ContactSupportOutlined, FaceRetouchingOffSharp } from '@mui/icons-material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
//session
import { ReactSession } from 'react-client-session';
// ==============================|| SAMPLE PAGE ||============================== //
const Espace_recruteur = () => {
    let history = useNavigate();
    //console.log(senddata);
    const [users, setUsers] = useState([]);
    const [origin, setOrigin] = useState([]);
    const { name, email,role } = AuthUser();
  
    const handleCloseGen = () => {
        setOpengen(false);
    };
    useEffect(() => {
        if(email!='' && (role==='Admin' || role==='Recruteur'))
        {
            load();
        }
        else {
            history('/login');
        }
    }, []);
    const load = () => {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/listUsers';
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json.data);
                setUsers(json);
                setOrigin(json);
            } catch (error) {
                //console.error('error');
            }
        }
        f();
    };
    const deleteuser =(id) => {
    if(role==='Admin')
    {
        axios
                .post('https://sourcing.openmind-its.com/api/deleteUser', {
                    idUser: id
                })
                .then(function (response) {
                    alert("L'utilisateur est bien supprimé");
                    history('/vue_recruteur_ListUsers');
                   // console.log(response);
                })
                .catch(function (error) {
                 alert("Echec de suppression");
                    history('/vue_recruteur_ListUsers');

                });
        
            }
    }
    const redirection = (e) => {
        history('/vue_recruteur_AddUser');
    };

    //supprimer

    const reload = () => {
        load();
    };
    const [opengen, setOpengen] = React.useState(false);

    const redirectionGen = (e) => {
        // history('/vue_entreprise_offre');
        setOpengen(true);
    };
    
    //Search
    const handleSearch = (event) => {
        if (event.target.value == 'Entreprise') {
            setUsers(origin.filter((cand) => cand.role.includes('Entreprise')));
        } else if (event.target.value == 'Recruteur') {
            setUsers(origin.filter((cand) => cand.role.includes('Recruteur')));
        } else if (event.target.value == 'Candidat') {
            setUsers(origin.filter((cand) => cand.role.includes('Candidat')));
        } else if (event.target.value == 'Valideur') {
            setUsers(origin.filter((cand) => cand.role.includes('Valideur')));
        }
        else if (event.target.value == 'Admin') {
            setUsers(origin.filter((cand) => cand.role.includes('Admin')));
        } else {
            load();
            setUsers(users);
        }
        // setSearch(event.target.value);
    };
   
    return (
        <MainCard title="Liste Users">
            <Button onClick={redirection} variant="contained" color="primary">
                Ajouter nouveau Utilisateur
            </Button>
            <Box mt={1} sx={{ float: 'right', width: '14rem' }}>
                <FormControl fullWidth>
                    <InputLabel id="Role">Filtrer par Role </InputLabel>
                    <Select labelId="Role" label="Filtrer par Role" id="role" name="role" onChange={handleSearch}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="Entreprise">Entreprise</MenuItem>
                        <MenuItem value="Recruteur">Recruteur</MenuItem>
                        <MenuItem value="Candidat">Candidat</MenuItem>
                        <MenuItem value="Valideur">Valideur</MenuItem>
                        <MenuItem value="Admin">Admin</MenuItem>

                    </Select>
                </FormControl>
            </Box>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Grid item>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Id</TableCell>
                                                    <TableCell align="right">Nom</TableCell>
                                                    <TableCell align="right">Email</TableCell>
                                                    <TableCell align="right">Role</TableCell>
                                                    <TableCell align="right">Date création</TableCell>
                                                    <TableCell align="right"></TableCell>
                                                    <TableCell align="right"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {users.length != 0 ? (
                                                    users.map((row) => (
                                                        
                                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                            <TableCell component="th" scope="row">
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="right">{row.name}</TableCell>
                                                            <TableCell align="right">{row.email}</TableCell>
                                                            <TableCell align="right">{row.role}</TableCell>
                                                            <TableCell align="right">{row.created_at}</TableCell>

                                                            <TableCell align="right">
                                                                {' '}
                                                                <AnimateButton>
                                                                    <Button
                                                                        
                                                                        onClick={() => {
                                                                            history('/vue_recruteur_UpdateUser/' + row.id);
                                                                        }}
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </AnimateButton>
                                                               
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {' '}
                                                                <AnimateButton>
                                                                    <Button
                                                                        
                                                                        onClick={()=>deleteuser(row.id)}
                                                                        // onClick={redirectionGen} 
                                                                        size="small"
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        supprimer
                                                                    </Button>
                                                                </AnimateButton>
                                                                {/* <Dialog open={opengen} onClose={handleCloseGen}>
                    <DialogTitle>Suppression d'un utilisateur</DialogTitle>
                    <DialogContent>
                        <br></br>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                        <label>{"Êtes-vous sûr de supprimer l'utilisateur " + row.name + "?"}</label>

                        </Grid>
                      
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>deleteuser(row.id)}>Oui</Button>
                        <Button   onClick={() => {history('/vue_recruteur_listUsers');
                                                                        }}>Annuler</Button>
                    </DialogActions>
                                                                    </Dialog> */}
                                                            </TableCell>
                                                 
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow key="1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <Box mt={2} sx={{ textAlign: 'right' }}>
                                                            {'Aucun utilisateur avec ce Status '}
                                                        </Box>
                                                        
              
                                                    </TableRow>
                                                )}
         
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
};
export default Espace_recruteur;
