import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// material-ui
import { Navigate, useNavigate } from 'react-router-dom';

import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery,
    Select,
    MenuItem
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
// project imports
import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';
import AuthUser from 'AuthUser';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//table
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//function table
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const rows = [
    createData('Offre 1', 15, 1, 'validée'),
    createData('Offre 2', 1, 2, 'validée'),
    createData('Offre 3', 115, 3, 'Non validée'),
    createData('Offre 4', 25, 4, 'validée'),
    createData('Offre 5', 2, 5, 'Non validée')
];

// ==============================|| SAMPLE PAGE ||============================== //
const submitForm = (e) => {
    history.push('/vue_entreprise_offre');
};

const Entreprise_profileCandidat = () => {
  
    const{ email,role,id} = AuthUser(); 
    
    const [offres, setOffres] = useState([]);
    let history = useNavigate();
    //==================
    const  load = () => 
    {
        async function f() 
        {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/entreprise/listOffres/'+id;
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json);
                setOffres(json);
            } catch (error) {
                console.error('error');
            }
        }
        f();
        // async function f() 
        // {
        //     try {
        //         const requrl = 'https://sourcing.openmind-its.com/api/nombrecandidats/'+id;
        //         const resp = await fetch(requrl);
        //         const json = await resp.json();
        //         console.log(json);
        //         setOffres(json);
        //     } catch (error) {
        //         console.error('error');
        //     }
        // }
        // f();
      
    };
    useEffect(() => {
        if(email!='' && role ==='Entreprise'){
            load();
        }
        else{
            history('/login');
        }
    },[]);
    return(
        <> 
    <MainCard title="Liste Offres">
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    if (scriptedRef.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={submitForm}>
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Grid item>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                            <TableCell align="center">id Offre</TableCell>

                                                <TableCell align="center">Titre Offre</TableCell>
                                                <TableCell align="center">Status</TableCell>
                                                {/* <TableCell align="right">Etat</TableCell> */}
                                                <TableCell align="center">  Détails Candidats</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {/* { offres.length != 0 ? ( */}
                                          {  offres.map((row) => (
                                                <TableRow key=   {row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center" component="th" scope="row">
                                                      {row.id}
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                      {row.titre_Offre}
                                                    </TableCell>
                                                    {/* <TableCell align="center">{row.nombre}</TableCell> */}
                                                    <TableCell align="center">{row.Status}</TableCell>
                                                    {/* <TableCell align="center"></TableCell> */}
                                                    {/* <TableCell align="right"></TableCell> */}
                                                    <TableCell align="center">
                                                        {' '}
                                                        <AnimateButton>
                                                            <Button
                                                                disableElevation
                                                                //disabled={isSubmitting}
                                                                fullWidth
                                                                size="small"
                                                                type="submit"
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={()=>{ history('/vue_recruteur_listCandidatOffre/'+ row.id)}}
                                                            >
                                                               Détails
                                                            </Button>
                                                        </AnimateButton>
                                                    </TableCell>
                                                </TableRow>
                                                // ))
                                                // ) : (
                                                //     <TableRow key="1" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                //         <Box mt={2} sx={{ textAlign: 'right' }}>
                                                //             {'Aucun offre '}
                                                //         </Box>
                                                //     </TableRow>
                                                // )}
                                          )) };
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    </MainCard>

    </>
    
    )
};

export default Entreprise_profileCandidat;
