import { useState, useEffect } from 'react';
import { Box, Grid, TextField, MenuItem, Input, Select, Button, Alert, FormControl, InputLabel } from '@mui/material';
import * as Yup from 'yup';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useHistory, useParams } from 'react-router-dom';
// third party

import { Formik } from 'formik';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
//date designe

//table
import * as React from 'react';
// import package date
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

import Moment from 'moment';
// ==============================|| SAMPLE PAGE ||============================== //
const Recruteur_updateProfile = () => {
    let history = useNavigate(); //use for navigate previons
    const { id } = useParams();
    const [data, setData] = useState({
        candidats_id: '',
        titreduprofil: '',
        typeduprofil: '',
        cv: '',
        dossiercompetence: '',
        pretentionssalariales: '',
        status: '',
        show: 'false'
    });
    //const { nom, prenom, dateNaissance, ville, pays, email, cv, titre, etat, mobilite, active, pretentionSalarial } = $user;

    useEffect(() => {
if( email!='' && (role==='Recruteur' || role==='Admin'))
    {
        async function f() {
            try {
                const requrl = 'https://sourcing.openmind-its.com/api/Profils/30';
                const resp = await fetch(requrl);
                const json = await resp.json();
                console.log(json.profil);
                setData(json.profil);
            } catch (error) {
                console.error('error');
            }
        }
        f();
    }
    else
        history('/login');
    }, []);
    const handlChanged = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    //ModifierCandidat :
    const submitForm = (e) => {
        const formatDate = Moment(data.datenaissance);
        // alert(formatDate.format('YYYY-MM-DD'));
        /*  if (
            data.nom == '' ||
            data.prenom == '' ||
            data.dateNaissance == '' ||
            data.ville == '' ||
            data.pays == '' ||
            data.email == '' ||
            data.titre == '' ||
            data.etat == '' ||
            data.mobilite == '' ||
            data.active == '' ||
            data.pretentionSalarial == ''
        ) {
            //alert
            setData((data.show = true));
            //up candidasett
        } else {*/
        console.log(data);
        axios
            .put('https://sourcing.openmind-its.com/api/Candidats/' + id, {
                candidats_id: data.candidats_id,
                titreduprofil: data.titreduprofil,
                typeduprofil: data.typeduprofil,
                cv: data.cv,
                dossiercompetence: data.dossiercompetence,
                pretentionssalariales: data.pretentionssalariales,
                audioentretien: data.audioentretien,
                status: data.status
            })
            .then(function (response) {
                setData((data.show = false));
                history('/vue_recruteur_listCandidats');
            })
            .catch(function (error) {
                //setData((data.show = true));
                alert(error);
            });
        //}
    };

    //initialiser et afficher la date selectionner | startDate= retourner date,setStartDate = remplir date
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            {data.show ? (
                <div></div>
            ) : (
                <Alert variant="filled" severity="error">
                    Merci d'inserer tout les champs !!
                </Alert>
            )}
            <MainCard title="Modifier Profil">
                <Formik initialValues={{}} validationSchema={Yup.object().shape({})}>
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="titreduprofil"
                                                name="titreduprofil"
                                                id="titreduprofil"
                                                type="text"
                                                value={data.titreduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="typeduprofil"
                                                name="typeduprofil"
                                                id="typeduprofil"
                                                type="text"
                                                value={data.typeduprofil}
                                                onChange={handlChanged}
                                            />
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="cv"
                                                id="cv"
                                                name="cv"
                                                type="file"
                                                value={data.cv}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="dossiercompetence"
                                                name="dossiercompetence"
                                                id="dossiercompetence"
                                                type="file"
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="pretentionssalariales"
                                                name="pretentionssalariales"
                                                id="pretentionssalariales"
                                                type="text"
                                                value={data.pretentionssalariales}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="audioentretien"
                                                name="audioentretien"
                                                id="audioentretien"
                                                type="file"
                                                value={data.audioentretien}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="status"
                                                name="status"
                                                id="status"
                                                type="text"
                                                value={data.status}
                                                onChange={handlChanged}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box sx={{ mt: 2 }}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        disabled={isSubmitting}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={submitForm}
                                                    >
                                                        + Modifier
                                                    </Button>
                                                </AnimateButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </MainCard>
        </>
    );
};
export default Recruteur_updateProfile;
